import { LoadingButton } from '@mui/lab';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import MainCard from 'ui-component/cards/MainCard';
import DataRender from 'ui-component/tables/DataRender';
import { formatErpResponse } from 'utils/utils';
import { modalStyle } from 'views/utilities/ModalStyle';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

const LoyaltyProgramList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [selectionModel, setSelectionModel] = useState([]);

    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const pricingRulesReqData = {
        doctype: 'Loyalty Program',
        fields: ['`tabLoyalty Program`.`name`', '`tabLoyalty Program`.`owner`', '`tabLoyalty Program`.`creation`'],
        start: 0,
        page_length: 500
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, pricingRulesReqData, 'POST');

    const handleEdit = (row) => {
        navigate(`/crm/loyatly-program/${row}`);
    };

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Loyalty Program', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Le programme de fidélité a été supprimée avec succès' }));
                    refetch({});
                    handleClose();
                }
            });
    };

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:deletePermanently')} <strong>{selectionModel}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    Non
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    Oui
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {t('crm:lps')}
            <Box>
                <Grid container spacing={1}>
                    {false && (
                        <>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button
                                        startIcon={<DeleteIcon color="error" />}
                                        variant="outlined"
                                        color="error"
                                        onClick={handleOpen}
                                        sx={{ marginTop: -3 }}
                                    >
                                        {t('common:delete')}
                                    </Button>
                                )}
                            </Grid>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button
                                        startIcon={<EditIcon />}
                                        variant="outlined"
                                        color="blue"
                                        onClick={() => handleEdit(selectionModel)}
                                        sx={{ marginTop: -3 }}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                )}
                            </Grid>
                        </>
                    )}
                    <Grid item>
                        <Button
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            onClick={() => navigate('/crm/loyatly-program')}
                            sx={{ marginTop: -3 }}
                        >
                            {t('crm:addlp')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

    return (
        <div>
            <DataRender
                refetch={refetch}
                data={data}
                loading={isPending}
                selectionModel={selectionModel}
                title={title}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
        </div>
    );
};

export default LoyaltyProgramList;
