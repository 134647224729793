import { LoadingButton } from '@mui/lab';
import { Chip, Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import MainCard from 'ui-component/cards/MainCard';
import InfoIcon from '@mui/icons-material/Info';
import { CurrencyFormatter, formatErpResponse } from 'utils/utils';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

const SalesInvoiceReturn = ({ mappedDoc, refetchDoc, handleClose, company }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [loadingButton, setLoadingButton] = useState(false);

    const handleMakeReturn = () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: { ...mappedDoc, status: 'Return' }, action: 'Submit' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Sales Return successfully created' }));
                    refetchDoc({});
                    handleClose();
                }
            });
    };

    return (
        <MainCard divider title={'Sales Return'}>
            <Box display={'flex'} alignItems={'center'} pb={1} sx={{ boxShadow: '0px 5px 5px -5px rgba(0, 0, 0, 0.75)' }}>
                <InfoIcon sx={{ mr: 2 }} />
                <Box>
                    <strong>
                        Return Against: {mappedDoc?.return_against}
                        <br />
                        Party: Customer
                        <br />
                        Customer: {mappedDoc?.customer}
                        <br />
                        Posting Date: {mappedDoc?.posting_date}
                        <br />
                    </strong>
                </Box>
            </Box>
            <PerfectScrollbar style={{ height: 250, overflowX: 'hidden' }}>
                <Box mb={2} mt={2}>
                    <Divider>
                        <Chip label="Items" />
                    </Divider>
                </Box>
                {mappedDoc?.items?.length !== 0 && (
                    <>
                        <Table aria-label="simple table" sx={{ mt: -2 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ITEM</TableCell>
                                    <TableCell>ITEM CODE</TableCell>
                                    <TableCell>QTY</TableCell>
                                    <TableCell>AMOUNT</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mappedDoc?.items?.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{row?.item_name}</TableCell>
                                        <TableCell>{row?.item_code}</TableCell>
                                        <TableCell>{row?.qty}</TableCell>
                                        <TableCell>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                currency={mappedDoc?.currency}
                                                minimumFractionDigits={3}
                                                value={row?.amount}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <strong>
                            Total:{' '}
                            <CurrencyFormatter
                                locale="fr-FR"
                                currency={mappedDoc?.currency}
                                minimumFractionDigits={3}
                                value={mappedDoc?.total}
                            />
                            <br />
                            Total Taxes and Charges:{' '}
                            <CurrencyFormatter
                                locale="fr-FR"
                                currency={mappedDoc?.currency}
                                minimumFractionDigits={3}
                                value={mappedDoc?.total_taxes_and_charges}
                            />
                            <br />
                            Grand Total:{' '}
                            <CurrencyFormatter
                                locale="fr-FR"
                                currency={mappedDoc?.currency}
                                minimumFractionDigits={3}
                                value={mappedDoc?.grand_total}
                            />
                            <br />
                        </strong>
                    </>
                )}
            </PerfectScrollbar>
            <LoadingButton
                sx={{ mt: 6 }}
                fullWidth
                loading={loadingButton}
                variant="contained"
                size="large"
                color={'blue'}
                onClick={handleMakeReturn}
            >
                Make Sales Return
            </LoadingButton>
        </MainCard>
    );
};

export default SalesInvoiceReturn;
