// assets
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    SupervisorAccountOutlinedIcon
};

// ==============================|| USERS MENU ITEMS ||============================== //

const onlineCommercial = {
    id: 'online-users',
    type: 'group',
    role: [ROLE.ADMIN, ROLE.SALES],
    children: [
        {
            id: 'online-users',
            title: 'users:OnlineUser',
            type: 'item',
            url: '/online/users',
            icon: icons.SupervisorAccountOutlinedIcon
        }
    ]
};

export default onlineCommercial;
