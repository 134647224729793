import { NotificationContent, getWarehouseCible, getWarehouseTarget } from './utils';

export const FormatNotifications = (notification) => {
    if (notification?.message) {
        let key = notification?.message?.doctype?.replaceAll(' ', '_').toUpperCase();
        let content = NotificationContent(key, notification);
        if (content && content.fields.length > 0) {
            let text = content.content;
            let warehouseC = getWarehouseCible(notification);
            text = content.fields.map((field) => {
                if (notification?.message?.doctype == 'Stock Entry' && field == 'to_warehouse') {
                    text = text?.replace(field, warehouseC);
                } else {
                    text = text?.replace(field, notification.message[field]);
                }
                return text;
            });
            return text[text.length - 1];
        }
    }

    return null;
};
