import { LoadingButton } from '@mui/lab';
import { Chip, Divider, InputLabel, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import MainCard from 'ui-component/cards/MainCard';
import InfoIcon from '@mui/icons-material/Info';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import { CurrencyFormatter, formatErpResponse } from 'utils/utils';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loader from 'ui-component/Loader';
import { useTranslation } from 'react-i18next';

const SalesInvoicePayment = ({ mappedDoc, refetchDoc, handleClose, refetchCount, company, refetchPayments }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingSelect, setLoadingSelect] = useState(false);

    const { data: modeOfPayment, isPending: isModeOfPaymentPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Mode of Payment',
            fields: ['`tabMode of Payment`.`name`'],
            filters: [['Mode of Payment Account', 'company', '=', company]]
        },
        'POST'
    );

    const [formState, setFormState] = useState({
        posting_date: moment().format('YYYY-MM-DD'),
        paid_amount: mappedDoc?.paid_amount ?? 0,
        reference_no: '',
        mode_of_payment: '',
        account: '',
        account_type: '',
        account_currency: '',
        account_balance: ''
    });

    const handleChange = (e) => {
        if (e.target.name === 'paid_amount') {
            if (e.target.value > 0) setFormState({ ...formState, [e.target.name]: e.target.value });
        } else {
            setFormState({ ...formState, [e.target.name]: e.target.value });
        }
    };

    const handleModePaymentChange = (e) => {
        setLoadingSelect(true);
        let data = {
            mode_of_payment: e?.target?.value,
            company: company
        };
        if (e?.target?.value) {
            fetch(`${process.env.REACT_APP_API_URI}/api/save/payement-method`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (data?.message) {
                        // Details
                        let req = {
                            account: data?.message?.account,
                            date: formState.posting_date
                        };
                        fetch(`${process.env.REACT_APP_API_URI}/api/payment/get-account-details`, {
                            method: 'POST',
                            headers: header,
                            body: JSON.stringify(req)
                        })
                            .then((res) => {
                                setLoadingSelect(false);
                                return res.json();
                            })
                            .then((res) => {
                                if (res?.message) {
                                    setFormState({
                                        ...formState,
                                        mode_of_payment: e?.target?.value,
                                        account: data?.message?.account,
                                        account_type: res?.message?.account_type,
                                        account_balance: res?.message?.account_balance,
                                        account_currency: res?.message?.account_currency
                                    });
                                }
                            });
                    }
                });
        }
    };

    const handleMakePayment = () => {
        setLoadingButton(true);
        const data = {
            ...(formState?.mode_of_payment !== ''
                ? {
                      mode_of_payment: formState.mode_of_payment,
                      paid_to_account_type: formState.account_type,
                      paid_to: formState.account,
                      paid_to_account_balance: formState.account_balance,
                      paid_to_account_currency: formState.account_currency
                  }
                : {
                      paid_to: mappedDoc?.paid_to,
                      paid_to_account_currency: mappedDoc?.paid_to_account_currency,
                      paid_to_account_balance: mappedDoc?.paid_to_account_balance
                  }),
            owner: mappedDoc?.owner,
            __islocal: 1,
            __unsaved: 1,
            docstatus: 0,
            idx: 0,
            naming_series: mappedDoc?.naming_series,
            payment_type: mappedDoc?.payment_type,
            payment_order_status: mappedDoc?.payment_order_status,
            posting_date: formState.posting_date,
            company: mappedDoc?.company,
            party_type: mappedDoc?.party_type,
            party: mappedDoc?.party,
            party_name: mappedDoc?.party_name,
            contact_person: mappedDoc?.contact_person,
            contact_email: mappedDoc?.contact_email,
            party_balance: mappedDoc?.party_balance,
            paid_from: mappedDoc?.paid_from,
            paid_from_account_currency: mappedDoc?.paid_from_account_currency,
            paid_from_account_balance: mappedDoc?.paid_from_account_balance,
            paid_amount: parseFloat(formState.paid_amount),
            paid_amount_after_tax: 0,
            source_exchange_rate: 1,
            base_paid_amount: parseFloat(formState.paid_amount),
            base_paid_amount_after_tax: 0,
            received_amount: parseFloat(formState.paid_amount),
            received_amount_after_tax: 0,
            target_exchange_rate: 1,
            base_received_amount: parseFloat(formState.paid_amount),
            base_received_amount_after_tax: 0,
            total_allocated_amount: parseFloat(formState.paid_amount),
            base_total_allocated_amount: parseFloat(formState.paid_amount),
            unallocated_amount: 0,
            difference_amount: 0,
            doctype: 'Payment Entry',
            reference_no: formState.reference_no,
            reference_date: moment().format('YYYY-MM-DD'),
            references: [{ ...mappedDoc?.references?.[0], allocated_amount: parseFloat(formState.paid_amount) }]
        };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Submit' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Payment Entry successfully created' }));
                    refetchDoc({});
                    refetchPayments && refetchPayments({});
                    refetchCount && refetchCount({});
                    handleClose();
                }
            });
    };

    return (
        <MainCard divider title={'Payment'}>
            <Box display={'flex'} alignItems={'center'} pb={1} sx={{ boxShadow: '0px 5px 5px -5px rgba(0, 0, 0, 0.75)' }}>
                <InfoIcon sx={{ mr: 2 }} />
                <Box>
                    <strong>
                        Party: {mappedDoc?.party_type}
                        <br />
                        Customer: {mappedDoc?.party}
                        <br />
                        Posting Date: {formState.posting_date}
                        <br />
                    </strong>
                </Box>
            </Box>
            <PerfectScrollbar style={{ height: 250, overflowX: 'hidden' }}>
                <Box mb={2} mt={2}>
                    <Divider>
                        <Chip label="Amount" />
                    </Divider>
                </Box>
                <InputTextField
                    fullwidth
                    required
                    sx={{ mb: 2 }}
                    isCurrency
                    currency={mappedDoc?.paid_to_account_currency}
                    type="number"
                    handleChange={handleChange}
                    value={formState.paid_amount}
                    label="Paid Amount"
                    name="paid_amount"
                />
                <SelectForm
                    fullwidth
                    sx={{ mb: 2 }}
                    label={'Mode of Payment'}
                    value={formState.mode_of_payment}
                    handleChange={handleModePaymentChange}
                    data={modeOfPayment}
                    name="mode_of_payment"
                    propToRender="name"
                />
                {mappedDoc?.references?.length !== 0 && (
                    <>
                        <Box mb={2} mt={2}>
                            <Divider>
                                <Chip label="References" />
                            </Divider>
                        </Box>
                        <Table aria-label="simple table" sx={{ mt: -2 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>TYPE</TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell>GRAND TOTAL</TableCell>
                                    <TableCell>OUTSTANDING</TableCell>
                                    <TableCell>ALLOCATED</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mappedDoc?.references?.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{row?.reference_doctype}</TableCell>
                                        <TableCell>{row?.reference_name}</TableCell>
                                        <TableCell>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                currency={mappedDoc?.paid_to_account_currency}
                                                minimumFractionDigits={3}
                                                value={row?.total_amount}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                currency={mappedDoc?.paid_to_account_currency}
                                                minimumFractionDigits={3}
                                                value={row?.outstanding_amount}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                currency={mappedDoc?.paid_to_account_currency}
                                                minimumFractionDigits={3}
                                                value={parseFloat(formState.paid_amount)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                )}
            </PerfectScrollbar>
            <Box mb={2} mt={2}>
                <Divider>
                    <Chip label="Transaction ID" />
                </Divider>
            </Box>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                <OutlinedInput
                    required
                    name="reference_no"
                    placeholder={'Cheque/Reference No'}
                    value={formState.reference_no}
                    onChange={handleChange}
                />
            </Box>
            <LoadingButton
                disabled={!formState?.paid_amount || !formState?.reference_no || isModeOfPaymentPending || loadingSelect}
                sx={{ mt: 3 }}
                fullWidth
                loading={loadingButton}
                variant="contained"
                size="large"
                color={'blue'}
                onClick={handleMakePayment}
            >
                Make Payment
            </LoadingButton>
            {(isModeOfPaymentPending || loadingSelect) && <Loader />}
        </MainCard>
    );
};

export default SalesInvoicePayment;
