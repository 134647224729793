import React from 'react';
import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStyles } from 'views/utilities/Style';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useFetch from 'hooks/useFetch';
import Reasons from './components/Reasons';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import SelectForm from 'ui-component/form/SelectForm';
import { Chip, Divider } from '@mui/material';
import StockSettings from './components/StockSettings';
import NamingSeries from './components/NamingSeries';

const Settings = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loadingButtonReturnMotif, setLoadingButtonReturnMotif] = React.useState(false);
    const [reasonReturnMotif, setReasonReturnMotif] = React.useState('');
    const [loadingButtonStockMotif, setLoadingButtonStockMotif] = React.useState(false);
    const [reasonStockMotif, setReasonStockMotif] = React.useState('');

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const reasonsReqData = {
        doctype: 'Opportunity Lost Reason',
        fields: ['`tabOpportunity Lost Reason`.`name`'],
        start: 0,
        page_length: 50,
        order_by: '`tabOpportunity Lost Reason`.`modified` desc'
    };

    const reasonsReconciliationStockReqData = {
        doctype: 'Quotation Lost Reason',
        fields: ['`tabQuotation Lost Reason`.`name`'],
        start: 0,
        page_length: 50,
        order_by: '`tabQuotation Lost Reason`.`modified` desc'
    };

    const { data: reasons, isPending: isReasonsPending, refetch: refetchReasons } = useFetch(`/api/get-list`, reasonsReqData, 'POST');
    const {
        data: stockReconciliationReasons,
        isPending: isStockReconciliationReasonsPending,
        refetch: refetchStockReconciliationReasons
    } = useFetch(`/api/get-list`, reasonsReconciliationStockReqData, 'POST');

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />

            {t('crm:settings')}
        </Box>
    );
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleReturnReasonChange = (e) => {
        setReasonReturnMotif(e.target.value);
    };

    const handleStockReasonChange = (e) => {
        setReasonStockMotif(e.target.value);
    };

    const handleReturnReasonInsert = (event) => {
        event.preventDefault();
        setLoadingButtonReturnMotif(true);
        const content = {
            doctype: 'Opportunity Lost Reason',
            lost_reason: reasonReturnMotif
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                doc: content,
                action: 'Save'
            })
        })
            .then((res) => {
                setLoadingButtonReturnMotif(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    refetchReasons({});
                    setReasonReturnMotif('');
                    dispatch(SetNotification({ code: 'success', message: 'Reason Added' }));
                }
            });
    };

    const handleStockReasonInsert = (event) => {
        event.preventDefault();
        setLoadingButtonStockMotif(true);
        const content = {
            doctype: 'Quotation Lost Reason',
            order_lost_reason: reasonStockMotif
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                doc: content,
                action: 'Save'
            })
        })
            .then((res) => {
                setLoadingButtonStockMotif(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    refetchStockReconciliationReasons({});
                    setReasonStockMotif('');
                    dispatch(SetNotification({ code: 'success', message: 'Reason Added' }));
                }
            });
    };

    return (
        <Box width={'100%'}>
            <MainCard title={title}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label={t('settings:r')} value="1" />
                                <Tab label={t('settings:ss')} value="2" />
                                <Tab label={t('settings:ns')} value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Reasons
                                reasons={reasons}
                                isReasonsPending={isReasonsPending}
                                refetch={refetchReasons}
                                loadingButton={loadingButtonReturnMotif}
                                setLoadingButton={setLoadingButtonReturnMotif}
                                handleReasonChange={handleReturnReasonChange}
                                reason={reasonReturnMotif}
                                setReason={setReasonReturnMotif}
                                handleReasonInsert={handleReturnReasonInsert}
                                doctype="Opportunity Lost Reason"
                                title={t('crm:returnreasons')}
                            />
                            <Box mt={2}>
                                <Reasons
                                    reasons={stockReconciliationReasons}
                                    isReasonsPending={isStockReconciliationReasonsPending}
                                    refetch={refetchStockReconciliationReasons}
                                    loadingButton={loadingButtonStockMotif}
                                    setLoadingButton={setLoadingButtonStockMotif}
                                    handleReasonChange={handleStockReasonChange}
                                    reason={reasonStockMotif}
                                    setReason={setReasonStockMotif}
                                    handleReasonInsert={handleStockReasonInsert}
                                    doctype="Quotation Lost Reason"
                                    title={t('crm:stockreason')}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            <StockSettings />
                        </TabPanel>
                        <TabPanel value="3">
                            <NamingSeries />
                        </TabPanel>
                    </TabContext>
                </Box>
            </MainCard>
        </Box>
    );
};

export default Settings;
