export const itemSections = [
    { id: 'basic_section', label: 'products:infoDeBase' },
    { id: 'pricing_section', label: 'products:tarifs' },
    { id: 'reorder_section', label: 'products:reassort' },
    { id: 'inventory_section', label: 'products:inventory' },
    { id: 'serial_nos_and_batches_section', label: 'products:numSerieLot' },
    { id: 'sales_details_section', label: 'products:detailVente' },
    { id: 'supplier_details_section', label: 'products:detailsSupplier' },
    { id: 'foreign_trade_details_section', label: 'products:detailCom' }
];

export const itemFields = [
    {
        label: 'Item Code',
        fieldname: 'item_code',
        section: 'basic_section'
    },
    {
        label: 'Item Name',
        fieldname: 'item_name',
        section: 'basic_section'
    },
    {
        label: 'Item Group',
        fieldname: 'item_group',
        section: 'basic_section'
    },
    {
        label: 'Default Unit of Measure',
        fieldname: 'stock_uom',
        section: 'basic_section'
    },
    {
        label: 'Disabled',
        fieldname: 'disabled',
        section: 'basic_section'
    },
    {
        label: 'Valuation Rate',
        fieldname: 'valuation_rate',
        section: 'pricing_section'
    },
    {
        label: 'Standard Selling Rate',
        fieldname: 'standard_rate',
        section: 'pricing_section'
    },
    {
        label: 'Description',
        fieldname: 'description',
        section: 'basic_section'
    },
    {
        label: 'Has Variants',
        fieldname: 'has_variants',
        section: 'basic_section'
    },
    {
        label: 'Brand',
        fieldname: 'brand',
        section: 'basic_section'
    },
    {
        label: 'Shelf Life In Days',
        fieldname: 'shelf_life_in_days',
        section: 'inventory_section'
    },
    {
        label: 'End of Life',
        fieldname: 'end_of_life',
        section: 'inventory_section'
    },
    {
        label: 'Default Material Request Type',
        fieldname: 'default_material_request_type',
        section: 'inventory_section'
    },
    {
        label: 'Valuation Method',
        fieldname: 'valuation_method',
        section: 'inventory_section'
    },
    {
        label: 'Warranty Period (in days)',
        fieldname: 'warranty_period',
        section: 'inventory_section'
    },
    {
        label: 'Weight Per Unit',
        fieldname: 'weight_per_unit',
        section: 'inventory_section'
    },
    {
        label: 'Weight UOM',
        fieldname: 'weight_uom',
        section: 'inventory_section'
    },
    {
        label: 'Barcodes',
        fieldname: 'barcodes',
        section: 'basic_section'
    },
    {
        label: 'Reorder level based on Warehouse',
        fieldname: 'reorder_levels',
        section: 'reorder_section'
    },
    {
        label: 'Has Batch No',
        fieldname: 'has_batch_no',
        section: 'serial_nos_and_batches_section'
    },
    {
        label: 'Has Serial No',
        fieldname: 'has_serial_no',
        section: 'serial_nos_and_batches_section'
    },
    {
        label: 'Item Defaults',
        fieldname: 'item_defaults',
        section: 'basic_section'
    },
    {
        label: 'Default Purchase Unit of Measure',
        fieldname: 'purchase_uom',
        section: 'reorder_section'
    },
    {
        label: 'Minimum Order Qty',
        fieldname: 'min_order_qty',
        section: 'reorder_section'
    },
    {
        label: 'Safety Stock',
        fieldname: 'safety_stock',
        section: 'reorder_section'
    },
    {
        label: 'Allow Purchase',
        fieldname: 'is_purchase_item',
        section: 'reorder_section'
    },
    {
        label: 'Lead Time in days',
        fieldname: 'lead_time_days',
        section: 'reorder_section'
    },
    {
        label: 'Supplier Items',
        fieldname: 'supplier_items',
        section: 'supplier_details_section'
    },
    {
        label: 'Last Purchase Rate',
        fieldname: 'last_purchase_rate',
        section: 'reorder_section'
    },
    {
        label: 'Country of Origin',
        fieldname: 'country_of_origin',
        section: 'foreign_trade_details_section'
    },
    {
        label: 'Customs Tariff Number',
        fieldname: 'customs_tariff_number',
        section: 'foreign_trade_details_section'
    },
    {
        label: 'Default Sales Unit of Measure',
        fieldname: 'sales_uom',
        section: 'sales_details_section'
    },
    {
        label: 'Grant Commission',
        fieldname: 'grant_commission',
        section: 'sales_details_section'
    },
    {
        label: 'Allow Sales',
        fieldname: 'is_sales_item',
        section: 'sales_details_section'
    },
    {
        label: 'Max Discount (%)',
        fieldname: 'max_discount',
        section: 'sales_details_section'
    },
    {
        label: 'Taxes',
        fieldname: 'taxes',
        section: 'pricing_section'
    },
    {
        label: 'Item Collection',
        fieldname: 'item_collection',
        section: 'basic_section'
    }
];
