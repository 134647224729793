import React, { useState } from 'react';
import { Select, MenuItem, FormControl, Button, InputLabel, OutlinedInput, Grid, IconButton, Tooltip } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import Box from '@mui/material/Box';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';
import { LoadingButton } from '@mui/lab';
import { formatErpResponse } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import validator from 'validator';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useGetAxios from 'hooks/useGetAxios';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';

const SupplierModalForm = ({ handleClose, loadingButton, setLoadingButton, refetch, doc, isEdit }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [state, setState] = useState({
        doctype: 'Supplier',
        supplier_name: isEdit ? doc?.supplier_name : '',
        supplier_type: isEdit ? doc?.supplier_type : '',
        supplier_group: isEdit ? doc?.supplier_group : '',
        country: isEdit ? doc?.country : '',
        email_id: isEdit ? doc?.email_id : '',
        mobile_no: isEdit ? doc?.mobile_no : '',
        address_line1: isEdit ? doc?.__onload?.addr_list[0]?.address_line1 : '',
        city: isEdit ? doc?.__onload?.addr_list[0]?.city : ''
    });

    const [error, setError] = useState({
        supplier_name: false,
        supplier_type: false,
        supplier_group: false,
        email_id: false,
        mobile_no: false,
        country: false,
        city: false,
        address_line1: false
    });

    const handleChange = (e) => {
        setError({ ...error, [e.target.name]: false });
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const supplierGroupReqData = {
        doctype: 'Supplier Group',
        fields: [
            '`tabSupplier Group`.`name`',
            '`tabSupplier Group`.`supplier_group_name`',
            '`tabSupplier Group`.`parent_supplier_group`',
            '`tabSupplier Group`.`is_group`'
        ],
        order_by: '`tabSupplier Group`.`modified` desc'
    };

    const { data: supplierGroup, isSupplierGroupPending } = useFetch(`/api/get-list`, supplierGroupReqData, 'POST');
    const { data: countries, isPending: isCountriesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Country',
            fields: ['`tabCountry`.`name`']
        },
        'POST'
    );
    const { data: doc_contact } = useGetAxios(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Contact&name=${doc?.supplier_primary_contact}`
    );

    const { data: doc_address } = useGetAxios(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Address&name=${doc?.supplier_primary_address}`
    );

    const supplierGroupName = supplierGroup?.map((obj) => obj.supplier_group_name);

    const data = [
        {
            name: 'supplier_type',
            label: t('columns:supplierType'),
            value: state.supplier_type,
            items: ['Company', 'Individual'],
            error: error.supplier_type
        },
        {
            name: 'supplier_group',
            label: t('columns:supplierGroup'),
            value: state.supplier_group,
            items: supplierGroupName,
            error: error.supplier_group
        }
    ];

    const handleAddSupplier = async () => {
        setLoadingButton(true);
        if (isEdit) {
            await savecontact();
            await saveAddress();
            var editSupplier = doc;
            editSupplier.supplier_name = state?.supplier_name;
            editSupplier.address_line1 = state?.address_line1;
            editSupplier.supplier_type = state?.supplier_type;
            editSupplier.supplier_group = state?.supplier_group;
            editSupplier.country = state?.country;
            editSupplier.city = state?.city;
        }

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: isEdit ? editSupplier : state, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    if (!isEdit) {
                        dispatch(SetNotification({ message: t('core:createSuppMsg'), code: 'success' }));
                        refetch({});
                        handleClose();
                    } else {
                        dispatch(SetNotification({ message: t('core:updateSuppMsg'), code: 'success' }));
                        refetch({});
                        handleClose();
                    }
                }
            });
    };
    const savecontact = async () => {
        if ((state?.mobile_no !== doc?.mobile_no || state?.email_id !== doc?.email_id) && doc_contact) {
            const var_cantact = doc_contact;
            if (var_cantact?.email_ids?.length > 0) var_cantact.email_ids[0].email_id = state?.email_id;
            if (var_cantact?.phone_nos?.length > 0) {
                var_cantact.phone_nos[0].phone = state?.mobile_no;
                var_cantact.phone_nos[0].is_primary_phone = 1;
            }
            await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ doc: var_cantact, action: 'Save' })
            })
                .then((res) => {
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    }
                    return res.json();
                })
                .then((data) => {
                    return;
                });
        } else {
            return;
        }
    };

    const saveAddress = async () => {
        if (
            (state?.address_line1 !== doc?.__onload?.addr_list[0]?.address_line1 || state?.city !== doc?.__onload?.addr_list[0]?.city) &&
            doc_address
        ) {
            const var_address = doc_address;
            var_address.address_line1 = state?.address_line1;
            var_address.city = state?.city;
            var_address.is_primary_address = 1;

            await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ doc: var_address, action: 'Save' })
            })
                .then((res) => {
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    }
                    return res.json();
                })
                .then((data) => {
                    return;
                });
        } else {
            return;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const fieldsToValidate = [
            'supplier_name',
            'supplier_type',
            'supplier_group',
            'email_id',
            'mobile_no',
            'country',
            'city',
            'address_line1'
        ];

        let isValid = true;
        const nextState = { ...state };
        const nextError = { ...error };

        fieldsToValidate.forEach((field) => {
            if (nextState[field].trim() === '') {
                nextError[field] = true;
                isValid = false;
            } else {
                nextError[field] = false;
            }

            if (field === 'email_id' && nextState[field] !== '' && !validator.isEmail(nextState[field])) {
                nextError[field] = true;
                isValid = false;
            }

            if (field === 'mobile_no' && nextState[field] !== '' && !validator.isMobilePhone(nextState[field])) {
                nextError[field] = true;
                isValid = false;
            }
        });
        setError(nextError);
        if (isValid) {
            handleAddSupplier();
        }
    };

    if (isSupplierGroupPending || isCountriesPending) return <Loader />;

    return (
        <Box>
            <Box p={1}>
                <PerfectScrollbar style={{ overflow: 'scroll', height: 400 }}>
                    <Box sx={{ padding: '5px 0px' }}>
                        <FormControl fullWidth required>
                            <InputLabel error={error.supplier_name}> {t('columns:supplierName')}</InputLabel>
                            <OutlinedInput
                                label={t('columns:supplierName')}
                                autoComplete="off"
                                className={classes.formControll}
                                error={error.supplier_name}
                                placeholder={t('columns:supplierName')}
                                name="supplier_name"
                                value={state.supplier_name}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <Box justifyContent="center">
                            {data.map((row, index) => (
                                <Box mt={2} key={index}>
                                    <FormControl fullWidth required>
                                        <InputLabel id="demo-simple-select-label" error={row.error}>
                                            {row.label}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={row.label}
                                            name={row.name}
                                            value={row.value}
                                            onChange={handleChange}
                                            error={row.error}
                                        >
                                            {Array.isArray(row.items) &&
                                                row.items.map((item, index) => (
                                                    <MenuItem style={{ fontSize: 12 }} value={item} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            ))}
                        </Box>
                        <Box mt={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mt: 1 }} required>
                                        <InputLabel error={error.email_id}>Email</InputLabel>
                                        <OutlinedInput
                                            label={'Email'}
                                            value={state.email_id}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder="Email"
                                            name="email_id"
                                            error={error.email_id}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mt: 1 }} required>
                                        <InputLabel error={error?.city}>{t('columns:city')}</InputLabel>
                                        <OutlinedInput
                                            label={t('columns:city')}
                                            value={state.city}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder={t('columns:city')}
                                            name="city"
                                            error={error?.city}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ mt: 1 }} required>
                                        <InputLabel error={error?.address_line1}> {t('crm:addr')}</InputLabel>
                                        <OutlinedInput
                                            label={t('crm:addr')}
                                            value={state.address_line1}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder={t('crm:addr')}
                                            name="address_line1"
                                            error={error?.address_line1}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mt: 1 }} required>
                                        <InputLabel error={error.mobile_no}> {t('crm:phonenum')}</InputLabel>
                                        <OutlinedInput
                                            label={t('crm:phonenum')}
                                            value={state.mobile_no}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder={t('crm:phonenum')}
                                            name="mobile_no"
                                            error={error.mobile_no}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ mt: 1 }} required>
                                        <InputLabel error={error?.country} mt={0.5} id="demo-simple-select-label">
                                            {t('core:country')}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={t('core:country')}
                                            name="country"
                                            value={state?.country}
                                            onChange={handleChange}
                                            error={error?.country}
                                        >
                                            {Array.isArray(countries) &&
                                                countries.map((item, index) => (
                                                    <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                    {isEdit && (
                                        <Box display={'flex'} alignItems={'left'} sx={{ mt: 2 }}>
                                            <Tooltip title={t('core:addContact')} arrow>
                                                <IconButton
                                                    className={classes.addAddresIcon}
                                                    onClick={() => navigate(`/core/add-contact/${doc?.name}`)}
                                                >
                                                    <AddIcon color="blue" />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </PerfectScrollbar>
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                {t('common:cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <LoadingButton color="blue" size="large" variant="contained" loading={loadingButton} onClick={handleSubmit}>
                                {t('common:save')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default SupplierModalForm;
