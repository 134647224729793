import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, FormControl, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL INCOME DARK CARD ||============================== //

const OutOfStockDarkCard = ({ isLoading, title, icon, doc, reqData, setReqData, refetch }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [value, setValue] = useState('this year');

    const handleChange = (e) => {
        setValue(e.target.value);
        setReqData((prevState) => {
            const newFilters = [...prevState.filters];
            const index = newFilters.findIndex((filter) => filter[0] === 'Sales Order' && filter[1] === 'transaction_date');
            newFilters[index][3] = e.target.value;
            return { ...prevState, filters: newFilters };
        });
    };

    useEffect(() => {
        refetch({});
    }, [reqData, value]);

    function getOutOfStockValue() {
        let totalDemanded = doc?.reduce((total, order) => total + order.total_qty, 0);
        let totalNotSupplied = doc?.filter((el) => el?.reason === 'out-of-stock-item' && el?.status === 'Draft');
        let totalQtyNotSupplied = totalNotSupplied?.reduce((total, order) => total + order.total_qty, 0);

        return totalQtyNotSupplied && totalDemanded != 0 ? (totalQtyNotSupplied / totalDemanded) * 100 : 0.0;
    }

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ py: 2, pl: 1, pb: 0.5 }}>
                        <List sx={{ py: 0 }}>
                            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            backgroundColor: theme.palette.primary[800],
                                            color: '#fff'
                                        }}
                                    >
                                        {icon}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{
                                        py: 0,
                                        mt: 0.45,
                                        mb: 0.45
                                    }}
                                    primary={
                                        <Box display={'flex'} justifyContent={'space-between'}>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{ whiteSpace: 'nowrap' }}
                                                color={'Menu'}
                                                fontSize={14}
                                                fontWeight={600}
                                            >
                                                {title}
                                            </Typography>
                                            <FormControl sx={{ minWidth: 100, maxHeight: 5, marginRight: 3 }}>
                                                <Select
                                                    size="small"
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={value}
                                                    onChange={handleChange}
                                                >
                                                    {[
                                                        {
                                                            key: 'last month',
                                                            value: t('dashboard:lastmonth')
                                                        },
                                                        {
                                                            key: 'last week',
                                                            value: t('dashboard:lastweek')
                                                        },
                                                        {
                                                            key: 'last year',
                                                            value: t('dashboard:lastyear')
                                                        },
                                                        {
                                                            key: 'last quarter',
                                                            value: t('dashboard:lastquarter')
                                                        },
                                                        {
                                                            key: 'last 6 months',
                                                            value: t('dashboard:last6months')
                                                        },
                                                        {
                                                            key: 'yesterday',
                                                            value: t('dashboard:yesterday')
                                                        },
                                                        {
                                                            key: 'today',
                                                            value: t('dashboard:today')
                                                        },
                                                        {
                                                            key: 'tomorrow',
                                                            value: t('dashboard:tomorrow')
                                                        },
                                                        {
                                                            key: 'this month',
                                                            value: t('dashboard:thismonth')
                                                        },
                                                        {
                                                            key: 'this week',
                                                            value: t('dashboard:thisweek')
                                                        },
                                                        {
                                                            key: 'this year',
                                                            value: t('dashboard:thisyear')
                                                        },
                                                        {
                                                            key: 'this quarter',
                                                            value: t('dashboard:thisquarter')
                                                        },
                                                        {
                                                            key: 'next month',
                                                            value: t('dashboard:nextmonth')
                                                        },
                                                        {
                                                            key: 'next week',
                                                            value: t('dashboard:nextweek')
                                                        },
                                                        {
                                                            key: 'next quarter',
                                                            value: t('dashboard:nextquarter')
                                                        },
                                                        {
                                                            key: 'next 6 months',
                                                            value: t('dashboard:next6months')
                                                        },
                                                        {
                                                            key: 'next year',
                                                            value: t('dashboard:nextyear')
                                                        }
                                                    ].map((row) => (
                                                        <MenuItem value={row.key} key={row.key}>
                                                            {row.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    }
                                    secondary={
                                        <div
                                            style={{
                                                marginTop: 0.25,
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 10,
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography variant="h4" color={'#fff'}>
                                                {getOutOfStockValue()?.toFixed(2) + ' %'}
                                            </Typography>
                                        </div>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

OutOfStockDarkCard.propTypes = {
    isLoading: PropTypes.bool
};

export default OutOfStockDarkCard;
