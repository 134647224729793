import { Button, Grid, Modal, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { modalStyle } from 'views/utilities/ModalStyle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { LoadingButton } from '@mui/lab';
import useGetAxios from 'hooks/useGetAxios';
import FileList from './components/FileList';
import LoadingList from './components/LoadingList';

const AllFilesList = () => {
    const [loadingButton, setLoadingButton] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    const {
        data: filesList,
        isPending: isFilesListPending,
        refetch: refetchFilesList
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/get-files-list`, null, true);

    const [selectedFile, setSelectedFile] = useState();

    const uploadHandler = (event) => {
        setSelectedFile(event?.target?.files?.[0]);
    };

    const fileUploader = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Files'}>
                <Box p={1} display={'flex'} alignItems={'center'} flexDirection={'row'} bgcolor={'Menu'} sx={{ borderRadius: 2 }}>
                    <UploadFileIcon />
                    <Typography id="modal-modal-description" color={'primary'} sx={{ ml: 2, fontSize: 14 }} fontWeight={500}>
                        {selectedFile?.name}
                    </Typography>
                    <Typography id="modal-modal-description" color={'primary'} sx={{ ml: 2, fontSize: 14 }}>
                        {selectedFile?.type}
                    </Typography>
                    <Typography id="modal-modal-description" color={'error'} sx={{ ml: 2, fontSize: 14, width: 20 }}>
                        {selectedFile?.size && (selectedFile?.size / 1024 / 1024).toFixed(2)} MB
                    </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton color="blue" size="large" onClick={() => {}} variant="contained" loading={loadingButton}>
                                    Upload
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    useEffect(() => {
        if (selectedFile) {
            handleOpen();
        }
    }, [selectedFile]);

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {'Liste de Fichiers'}
            {/* <Button variant="contained" color="blue" component="label">
                Import
                <input type="file" hidden onChange={uploadHandler} />
            </Button> */}
        </Box>
    );

    return (
        <>
            <MainCard title={title}>
                {isFilesListPending ? <LoadingList /> : <FileList files={filesList?.data ?? []} refetchFilesList={refetchFilesList} />}
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                {fileUploader}
            </Modal>
        </>
    );
};

export default AllFilesList;
