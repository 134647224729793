import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: function (lngs, namespaces) {
                return '/locales/i18n/{{ns}}/{{lng}}.json';
            }
        },
        fallbackLng: 'en',
        lng: 'en',
        debug: false,
        ns: [
            'dashboard',
            'products',
            'stores',
            'columns',
            'common',
            'routes',
            'stores',
            'login',
            'channels',
            'currencies',
            'variantstypes',
            'crm',
            'core',
            'documents',
            'settings',
            'qrcodes'
        ],
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;
