import { LoadingButton } from '@mui/lab';
import { Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import { formatErpResponse, isExistInDoc } from 'utils/utils';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SetDoc2Duplicate, SetNotification } from 'store/services/api';
import { DropzoneArea } from 'react-mui-dropzone';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import { useTranslation } from 'react-i18next';
import useFetch from 'hooks/useFetch';
import { itemFields, itemSections } from 'views/products/customizeForm/components/itemFieldsValues';
import ArrowDropDownCircleTwoToneIcon from '@mui/icons-material/ArrowDropDownCircleTwoTone';
import { Accordion, AccordionDetails, AccordionSummary } from './accordion-components/AccordionComponents';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import ReorderForm from './components/ReorderForm';
import BarcodesForm from './components/BarcodesForm';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import SupplierItemsForm from './components/SupplierItemsForm';
import ItemPriceForm from './components/ItemPriceForm';

const TemplateProdForm = ({
    storedFiles,
    refetchFiles,
    doc,
    itemGroups,
    companies,
    warehouses,
    brands,
    itemTaxTemplates,
    itemAttributes,
    priceLists,
    isSimple,
    editToggled,
    toPrint,
    uoms,
    suppliers,
    itemCollections
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const docToDuplicate = useSelector((state) => state.pim.docToDuplicate);
    const theme = useTheme();

    const [selectedFields, setSelectedFields] = useState([]);
    const [sections, setSections] = useState([]);

    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    const itemPriceReqData = {
        doctype: 'Item Price',
        fields: ['`tabItem Price`.`price_list`', '`tabItem Price`.`price_list_rate`', '`tabItem Price`.`currency`'],
        filters: [['Item Price', 'item_code', '=', doc?.item_code]],
        start: 0,
        page_length: 20,
        order_by: '`tabItem Price`.`modified` desc'
    };

    const { data: itemPrice, isPending: isItemPricePending, refetch } = useFetch(`/api/get-list`, itemPriceReqData, 'POST');

    const { data: selectedFieldsInLara, isPending: isFieldsPending } = useFetch(
        `/api/product-fields`,
        { company: globalDefaults.default_company },
        'POST'
    );

    const [itemDefaults, setItemDefaults] = useState(() => ({
        doctype: 'Item Default',
        __islocal: 1,
        __unsaved: 1,
        company: globalDefaults.default_company,
        default_warehouse: '',
        parentfield: 'item_defaults',
        parenttype: 'Item',
        idx: 1,
        __unedited: false,
        default_price_list: ''
    }));

    const [formState, setFormState] = useState({
        // fixed values
        doctype: 'Item',
        __islocal: 1,
        __unsaved: 1,
        disabled: 0,
        allow_alternative_item: 0,
        is_stock_item: 1,
        include_item_in_manufacturing: 1,
        is_fixed_asset: 0,
        auto_create_assets: 0,
        is_grouped_asset: 0,
        end_of_life: '2099-12-31',
        default_material_request_type: 'Purchase',
        valuation_method: '',
        allow_negative_stock: 0,
        has_batch_no: 0,
        create_new_batch: 0,
        has_expiry_date: 0,
        retain_sample: 0,
        has_serial_no: 0,
        min_order_qty: 0,
        is_purchase_item: 1,
        is_customer_provided_item: 0,
        delivered_by_supplier: 0,
        enable_deferred_expense: 0,
        grant_commission: 1,
        is_sales_item: 1,
        enable_deferred_revenue: 0,
        inspection_required_before_purchase: 0,
        inspection_required_before_delivery: 0,
        is_sub_contracted_item: 0,
        published_in_website: 0,
        max_discount: '',
        stock_uom: 'Nos',
        weight_per_unit: '',
        // recently added
        safety_stock: 0,
        lead_time_days: 0,
        last_purchase_rate: 0,
        shelf_life_in_days: 0,
        warranty_period: '',
        sales_uom: '',
        purchase_uom: '',
        // changable values
        variant_based_on: 'Item Attribute',
        create_variant: 0,
        item_template: '',
        has_variants: 1,
        attributes: [],
        item_defaults: [],
        taxes: [],
        description: '',
        brand: '',
        item_code: '',
        item_name: '',
        item_group: '',
        item_collection: '',
        opening_stock: 0,
        standard_rate: 0,
        valuation_rate: 0
    });

    const [itemPriceState, setItemPriceState] = useState([]);
    const [reorderState, setReorderState] = useState([]);
    const [barcodesState, setBarcodesState] = useState([]);
    const [supplierItemsState, setSupplierItemsState] = useState([]);

    const [formErrState, setFormErrState] = useState({
        item_code: true,
        item_name: true,
        item_group: true
    });

    const [loadingButton, setLoadingButton] = useState(false);

    const [files, setFiles] = useState([]);

    const [ItemsFiles, setItemsFiles] = useState([]);

    const [openDropZone, setOpenDropZone] = useState(false);
    const [openBarcodes, setOpenBarcodes] = useState(false);

    // handle the change of the form states
    // >>>
    const handleItemDefaultsChange = (e) => {
        setItemDefaults({ ...itemDefaults, [e.target.name]: e.target.value });
    };

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value === '' && ['item_name', 'item_code'].includes(e.target.name)) {
            setFormErrState({ ...formErrState, [e.target.name]: true });
        } else {
            setFormErrState({ ...formErrState, [e.target.name]: false });
        }
    };

    const handleCheckChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.checked ? 1 : 0 });
    };

    const handleSelectChange = (event) => {
        const {
            target: { value }
        } = event;
        if (event.target.name == 'taxes') {
            setFormState({
                ...formState,
                taxes: [
                    {
                        doctype: 'Item Tax',
                        __islocal: 1,
                        __unsaved: 1,
                        parentfield: 'taxes',
                        parenttype: 'Item',
                        __unedited: false,
                        item_tax_template: value
                    }
                ]
            });
        } else {
            setFormState({ ...formState, [event.target.name]: value });
        }
        if (event.target.name === 'item_group') {
            setFormErrState({ ...formErrState, [event.target.name]: false });
        }
    };
    const [isChangedAttributes, setIsChangedAttributes] = useState(false);
    const handleAttributesChange = (e) => {
        let row = {
            docstatus: 0,
            doctype: 'Item Variant Attribute',
            __islocal: 1,
            __unsaved: 1,
            parentfield: 'attributes',
            parenttype: 'Item',
            __unedited: false,
            numeric_values: 0,
            from_range: 0,
            to_range: 0,
            increment: 0,
            attribute: e.target.name
        };
        let data = doc && editToggled && !isChangedAttributes ? [...doc?.attributes] : [...formState.attributes];
        setIsChangedAttributes(true);
        const rowIndex = data.findIndex((el) => el.attribute == e.target.name);
        if (rowIndex == -1 && e.target.checked) {
            data.push(row);
        } else if (rowIndex != -1 && !e.target.checked) {
            data = data.filter((el) => el.attribute != e.target.name);
        }
        setFormState({ ...formState, attributes: data });
    };

    const handleFilesChange = (files) => {
        setFiles(files);
    };

    // handle submitting the form & redirection
    // >>>
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleCreate = () => {
        setLoadingButton(true);
        const data = {
            doc: {
                ...formState,
                item_defaults: [itemDefaults],
                reorder_levels: reorderState,
                barcodes: barcodesState,
                supplier_items: supplierItemsState,
                ...(isSimple &&
                    !editToggled && { has_variants: 0, attributes: [], item_template: null, create_variant: 0, variant_based_on: null })
            },
            action: 'Save'
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    editToggled
                        ? dispatch(
                              SetNotification({ code: 'success', message: `Produit ${!isSimple ? 'modèle' : ''} sauvegardé avec succès` })
                          )
                        : dispatch(SetNotification({ code: 'success', message: `Produit ${!isSimple ? 'modèle' : ''} créé avec succès` }));

                    /*upload product files*/
                    if (files?.length !== 0) {
                        const header = {
                            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                            Authorization: JSON.parse(localStorage.getItem('user'))?.token
                        };
                        const formData = new FormData();
                        for (var x = 0; x < files?.length; x++) {
                            formData.append(`file_${x}`, files[x], files[x]?.name);
                        }
                        formData.append('doctype', 'Item');
                        formData.append('name', data.docs[0].name);

                        fetch(`${process.env.REACT_APP_API_URI}/api/upload-file`, {
                            method: 'POST',
                            headers: header,
                            body: formData
                        })
                            .then((res) => {})
                            .then((data) => {
                                navigate('/pim/products-view');
                            });
                    } else {
                        navigate('/pim/products-view');
                    }
                }
                dispatch(SetDoc2Duplicate(null));
            });
    };

    const renderSaveButton = () => {
        if (doc) {
            if (editToggled) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (itemPrice) {
            setItemPriceState(itemPrice);
        }
    }, [itemPrice]);

    useEffect(() => {
        if (editToggled && doc) {
            // set doc values to the form state
            setFormState({
                doctype: 'Item',
                naming_series: doc.naming_series,
                // __islocal: 1,
                __unsaved: 1,
                creation: doc.creation,
                modified: doc.modified,
                owner: doc.owner,
                modified_by: doc.owner,
                name: doc.name,
                disabled: doc.disabled,
                allow_alternative_item: doc.allow_alternative_item,
                is_stock_item: doc.is_stock_item,
                include_item_in_manufacturing: doc.include_item_in_manufacturing,
                is_fixed_asset: doc.is_fixed_asset,
                auto_create_assets: doc.auto_create_assets,
                is_grouped_asset: doc.is_grouped_asset,
                end_of_life: doc.end_of_life,
                default_material_request_type: doc.default_material_request_type,
                valuation_method: doc.valuation_method,
                allow_negative_stock: doc.allow_negative_stock,
                has_batch_no: doc.has_batch_no,
                create_new_batch: doc.create_new_batch,
                has_expiry_date: doc.has_expiry_date,
                retain_sample: doc.retain_sample,
                has_serial_no: doc.has_serial_no,
                min_order_qty: doc.min_order_qty,
                is_purchase_item: doc.is_purchase_item,
                is_customer_provided_item: doc.is_customer_provided_item,
                delivered_by_supplier: doc.delivered_by_supplier,
                enable_deferred_expense: doc.enable_deferred_expense,
                grant_commission: doc.grant_commission,
                is_sales_item: doc.is_sales_item,
                enable_deferred_revenue: doc.enable_deferred_revenue,
                inspection_required_before_purchase: doc.inspection_required_before_purchase,
                inspection_required_before_delivery: doc.inspection_required_before_delivery,
                is_sub_contracted_item: doc.is_sub_contracted_item,
                published_in_website: doc.published_in_website,
                max_discount: doc.max_discount,
                stock_uom: doc.stock_uom,
                uoms: doc.uoms,
                supplier_items: doc.supplier_items,
                reorder_levels: doc.reorder_levels,
                customer_items: doc.customer_items,
                variant_of: doc.variant_of ? doc.variant_of : null,
                weight_per_unit: doc.weight_per_unit,
                // changable values
                variant_based_on: doc.variant_based_on,
                create_variant: doc?.create_variant ? doc?.create_variant : 0,
                item_template: doc?.item_template ? doc?.item_template : '',
                has_variants: doc?.has_variants ? doc?.has_variants : null,
                attributes: doc?.attributes,
                item_defaults: [],
                taxes: doc.taxes,
                description: doc.description,
                brand: doc.brand,
                item_code: doc.item_code,
                item_name: doc.item_name,
                item_group: doc.item_group,
                item_collection: doc?.item_collection,
                opening_stock: doc.opening_stock,
                standard_rate: doc.standard_rate,
                valuation_rate: doc.valuation_rate
            });
            setItemDefaults({
                doctype: 'Item Default',
                __islocal: 1,
                __unsaved: 1,
                company: doc?.item_defaults?.[0]?.company,
                default_warehouse: doc?.item_defaults?.[0]?.default_warehouse,
                parentfield: 'item_defaults',
                parenttype: 'Item',
                idx: 1,
                __unedited: false,
                default_price_list: doc?.item_defaults?.[0]?.default_price_list
            });
            setReorderState(doc?.reorder_levels);
            setBarcodesState(doc?.barcodes);
            setSupplierItemsState(doc?.supplier_items);
        }
    }, [editToggled]);

    useEffect(() => {
        if (docToDuplicate) {
            setFormState({
                // fixed values
                doctype: 'Item',
                __islocal: 1,
                __unsaved: 1,
                disabled: docToDuplicate?.disabled,
                allow_alternative_item: docToDuplicate?.allow_alternative_item,
                is_stock_item: docToDuplicate?.is_stock_item,
                include_item_in_manufacturing: docToDuplicate?.include_item_in_manufacturing,
                is_fixed_asset: docToDuplicate?.is_fixed_asset,
                auto_create_assets: docToDuplicate?.auto_create_assets,
                is_grouped_asset: docToDuplicate?.is_grouped_asset,
                end_of_life: docToDuplicate?.end_of_life,
                default_material_request_type: docToDuplicate?.default_material_request_type,
                valuation_method: docToDuplicate?.valuation_method,
                allow_negative_stock: docToDuplicate?.allow_negative_stock,
                has_batch_no: docToDuplicate?.has_batch_no,
                create_new_batch: docToDuplicate?.create_new_batch,
                has_expiry_date: docToDuplicate?.has_expiry_date,
                retain_sample: docToDuplicate?.retain_sample,
                has_serial_no: docToDuplicate?.has_serial_no,
                min_order_qty: docToDuplicate?.min_order_qty,
                is_purchase_item: docToDuplicate?.is_purchase_item,
                is_customer_provided_item: docToDuplicate?.is_customer_provided_item,
                delivered_by_supplier: docToDuplicate?.delivered_by_supplier,
                enable_deferred_expense: docToDuplicate?.enable_deferred_expense,
                grant_commission: docToDuplicate?.grant_commission,
                is_sales_item: docToDuplicate?.is_sales_item,
                enable_deferred_revenue: docToDuplicate?.enable_deferred_revenue,
                inspection_required_before_purchase: docToDuplicate?.inspection_required_before_purchase,
                inspection_required_before_delivery: docToDuplicate?.inspection_required_before_delivery,
                is_sub_contracted_item: docToDuplicate?.is_sub_contracted_item,
                published_in_website: docToDuplicate?.published_in_website,
                max_discount: docToDuplicate?.max_discount,
                stock_uom: docToDuplicate?.stock_uom,
                uoms: docToDuplicate?.uoms,
                weight_per_unit: docToDuplicate?.weight_per_unit,
                // changable values
                variant_based_on: docToDuplicate?.variant_based_on,
                create_variant: docToDuplicate?.create_variant,
                item_template: docToDuplicate?.item_template,
                has_variants: docToDuplicate?.has_variants,
                attributes: docToDuplicate?.attributes,
                item_defaults: [],
                taxes: docToDuplicate?.taxes,
                description: docToDuplicate?.description,
                brand: docToDuplicate?.brand,
                item_code: docToDuplicate?.item_code + ' - Copie',
                item_name: docToDuplicate?.item_name + ' - Copie',
                item_group: docToDuplicate?.item_group,
                item_collection: docToDuplicate?.item_collection,
                opening_stock: docToDuplicate?.opening_stock,
                standard_rate: docToDuplicate?.standard_rate,
                valuation_rate: docToDuplicate?.valuation_rate
            });
            setItemDefaults({
                doctype: 'Item Default',
                __islocal: 1,
                __unsaved: 1,
                company: docToDuplicate?.item_defaults[0].company,
                default_warehouse: docToDuplicate?.item_defaults[0].default_warehouse,
                parentfield: 'item_defaults',
                parenttype: 'Item',
                idx: 1,
                __unedited: false,
                default_price_list: docToDuplicate?.item_defaults[0].default_price_list
            });
            setReorderState(docToDuplicate?.reorder_levels);
            setBarcodesState(docToDuplicate?.barcodes);
            setSupplierItemsState(docToDuplicate?.supplier_items);
        }
    }, [docToDuplicate]);

    useEffect(() => {
        const header = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        if (storedFiles?.filePaths) {
            const fetchFiles = async () => {
                const promises = storedFiles?.filePaths?.map(async (url) => {
                    const response = await fetch(`${process.env.REACT_APP_API_URI}/api/files`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({ fileUrl: url })
                    });
                    const fileType = response.headers.get('Content-Type');
                    const buffer = await response.arrayBuffer();
                    const blob = new Blob([buffer], { type: fileType });

                    const filename = url.substring(url.lastIndexOf('/') + 1);

                    return new File([blob], filename, { type: fileType });
                });
                const files = await Promise.all(promises);
                setItemsFiles(files);
            };

            fetchFiles();
        }
    }, [storedFiles]);

    const MyFileLink = ({ file }) => {
        const handleDownload = (event) => {
            event.preventDefault();
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        };

        function findFilePathByName(name, filePaths) {
            for (const filePath of filePaths) {
                const parts = filePath.split('/');
                const fileName = parts[parts.length - 1];
                if (fileName === name) {
                    return filePath;
                }
            }
            return null;
        }

        const handleDeleteFile = async () => {
            const url = findFilePathByName(file?.name, storedFiles?.filePaths);
            if (url) {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/delete-file`, {
                    method: 'DELETE',
                    headers: header,
                    body: JSON.stringify({ fileUrl: url })
                });
                if (response?.ok) {
                    dispatch(SetNotification({ code: 'success', message: 'File deleted successfully' }));
                    refetchFiles({});
                } else {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
            } else {
                return null;
            }
        };

        let preview = (
            <div>
                {file.type.startsWith('image/') ? (
                    <img style={{ width: 64, height: 64, objectFit: 'cover' }} src={URL.createObjectURL(file)} alt={file.name} />
                ) : (
                    <InsertDriveFileRoundedIcon sx={{ width: 64, height: 64, objectFit: 'cover' }} />
                )}
            </div>
        );

        return (
            <Paper
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover
                    },
                    padding: theme.spacing(2),
                    marginBottom: theme.spacing(1)
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }}>
                    {preview}
                    <Typography variant="subtitle1">{file.name}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                    <IconButton onClick={handleDownload}>
                        <DownloadIcon />
                    </IconButton>
                    {editToggled ? (
                        <IconButton onClick={handleDeleteFile}>
                            <DeleteIcon color="error" />
                        </IconButton>
                    ) : null}
                </div>
            </Paper>
        );
    };

    useEffect(() => {
        if (selectedFieldsInLara?.[0]?.sections) {
            setSections(JSON.parse(selectedFieldsInLara?.[0]?.sections));
        } else {
            setSections(itemSections);
        }
        if (selectedFieldsInLara?.[0]?.product_required_fields) {
            setSelectedFields(JSON.parse(selectedFieldsInLara?.[0]?.product_required_fields));
        } else {
            setSelectedFields(itemFields?.filter((el) => el?.section === 'basic_section'));
        }
    }, [selectedFieldsInLara]);

    function getFieldsBySection(section) {
        const data = selectedFields.filter((el) => el.section === section);
        if (Array.isArray(data) && data?.length !== 0) {
            return data;
        } else {
            return [];
        }
    }

    function renderField(field) {
        switch (field.fieldname) {
            case 'customs_tariff_number':
                return field.label;
            case 'country_of_origin':
                return field.label;
            case 'max_discount':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            sx={{ mb: 2 }}
                            isPercentage
                            type="number"
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.max_discount : formState?.max_discount}
                            label={t('products:maxDiscount')}
                            name="max_discount"
                            disabled={doc && !editToggled}
                        />
                    </div>
                );
            case 'is_sales_item':
                return (
                    <div style={{ width: '50%' }} key={`${field.fieldname}`}>
                        <FormControlLabel
                            sx={{ marginLeft: '35%' }}
                            control={
                                <Checkbox
                                    checked={doc && !editToggled ? doc?.is_sales_item === 1 : formState.is_sales_item === 1}
                                    onChange={doc && !editToggled ? null : handleCheckChange}
                                    name={field.fieldname}
                                />
                            }
                            label={<Typography fontWeight={500}> {t('products:isSalesItem')}</Typography>}
                        />
                    </div>
                );
            case 'grant_commission':
                return (
                    <div style={{ width: '50%' }} key={`${field.fieldname}`}>
                        <FormControlLabel
                            sx={{ marginLeft: '35%' }}
                            control={
                                <Checkbox
                                    checked={doc && !editToggled ? doc?.grant_commission === 1 : formState.grant_commission === 1}
                                    onChange={doc && !editToggled ? null : handleCheckChange}
                                    name={field.fieldname}
                                />
                            }
                            label={<Typography fontWeight={500}>{t('products:grantCommission')}</Typography>}
                        />
                    </div>
                );
            case 'sales_uom':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('products:salesUom')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.sales_uom : formState?.sales_uom}
                            name={'sales_uom'}
                            data={uoms}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'has_serial_no':
                return (
                    <div style={{ width: '50%' }} key={`${field.fieldname}`}>
                        <FormControlLabel
                            sx={{ marginLeft: '35%' }}
                            control={
                                <Checkbox
                                    checked={doc && !editToggled ? doc?.has_serial_no === 1 : formState.has_serial_no === 1}
                                    onChange={doc && !editToggled ? null : handleCheckChange}
                                    name={field.fieldname}
                                />
                            }
                            label={<Typography fontWeight={500}> {t('products:hasSerialNo')}</Typography>}
                        />
                    </div>
                );
            case 'has_batch_no':
                return (
                    <div style={{ width: '50%' }} key={`${field.fieldname}`}>
                        <FormControlLabel
                            sx={{ marginLeft: '35%' }}
                            control={
                                <Checkbox
                                    checked={doc && !editToggled ? doc?.has_batch_no === 1 : formState.has_batch_no === 1}
                                    onChange={doc && !editToggled ? null : handleCheckChange}
                                    name={field.fieldname}
                                />
                            }
                            label={<Typography fontWeight={500}> {t('products:hasBatchNo')}</Typography>}
                        />
                    </div>
                );
            case 'weight_uom':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('products:weightUOM')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.weight_uom : formState?.weight_uom}
                            name={'weight_uom'}
                            data={uoms}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'weight_per_unit':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'weight_per_unit'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.weight_per_unit : formState.weight_per_unit}
                            label={t('products:weightPerUnit')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'warranty_period':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'warranty_period'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.warranty_period : formState.warranty_period}
                            label={t('products:warrantyPeriod')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'valuation_method':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            label={t('products:valuationMethod')}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.valuation_method : formState.valuation_method}
                            name={'valuation_method'}
                            data={['FIFO', 'Moving Average', 'LIFO']}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'default_material_request_type':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            label={t('products:defaultMaterialRequestType')}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.default_material_request_type : formState.default_material_request_type}
                            name={'default_material_request_type'}
                            data={['Purchase', 'Material Transfer', 'Material Issue', 'Manufacture', 'Customer Provided']}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'end_of_life':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <FormDateTime
                            fullwidth
                            type="date"
                            label={t('products:endOfLife')}
                            name="end_of_life"
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.end_of_life : formState.end_of_life}
                            disabled={doc && !editToggled}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'shelf_life_in_days':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'shelf_life_in_days'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.shelf_life_in_days : formState.shelf_life_in_days}
                            label={t('products:shelfLifeInDays')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'last_purchase_rate':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'last_purchase_rate'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.last_purchase_rate : formState.last_purchase_rate}
                            label={t('products:lastPurchaseRate')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'lead_time_days':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'lead_time_days'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.lead_time_days : formState.lead_time_days}
                            label={t('products:leadTimeDays')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'supplier_items':
                return (
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: theme.palette.secondary.main,
                            margin: '20px 0px',
                            borderRadius: 10
                        }}
                        key={field.fieldname}
                    >
                        {doc ? (
                            <SupplierItemsForm
                                disabled={doc && !editToggled}
                                doc={doc}
                                formState={formState}
                                supplierItemsState={doc && !editToggled ? doc?.supplier_items : supplierItemsState}
                                setSupplierItemsState={setSupplierItemsState}
                                suppliers={suppliers}
                            />
                        ) : null}
                    </div>
                );
            case 'safety_stock':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'safety_stock'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.safety_stock : formState.safety_stock}
                            label={t('products:safetyStock')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'min_order_qty':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'min_order_qty'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.min_order_qty : formState.min_order_qty}
                            label={t('products:minOrderQty')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'purchase_uom':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('products:purchaseUOM')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.purchase_uom : formState?.purchase_uom}
                            name={'purchase_uom'}
                            data={uoms}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'reorder_levels':
                return (
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: theme.palette.secondary.main,
                            margin: '20px 0px',
                            borderRadius: 10
                        }}
                        key={field.fieldname}
                    >
                        <ReorderForm
                            field={field}
                            disabled={doc && !editToggled}
                            reorderState={doc && !editToggled ? doc?.reorder_levels : reorderState}
                            setReorderState={setReorderState}
                            warehouses={warehouses}
                        />
                    </div>
                );
            case 'taxes':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            label={t('products:taxModel')}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={
                                doc && !editToggled
                                    ? doc.taxes[0]?.item_tax_template
                                    : formState.taxes?.[0]?.item_tax_template
                                    ? formState.taxes?.[0]?.item_tax_template
                                    : ''
                            }
                            name={'taxes'}
                            data={itemTaxTemplates}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'standard_rate':
                return (
                    <>
                        <div style={{ width: '50%' }} key={field.fieldname}>
                            <InputTextField
                                fullwidth
                                disabled={doc && !editToggled}
                                name={'standard_rate'}
                                handleChange={doc && !editToggled ? null : handleChange}
                                value={doc && !editToggled ? doc?.standard_rate : formState.standard_rate}
                                label={t('dashboard:standardRate')}
                                type="number"
                                sx={{ mb: 1 }}
                            />
                        </div>
                        <div style={{ width: '50%' }} key={`${field.fieldname}-default_price_list`}>
                            <SelectForm
                                fullwidth
                                disabled={doc && !editToggled}
                                label={t('products:defaultPriceList')}
                                handleChange={doc && !editToggled ? null : handleItemDefaultsChange}
                                value={doc && !editToggled ? doc?.item_defaults?.[0]?.default_price_list : itemDefaults?.default_price_list}
                                name="default_price_list"
                                data={priceLists}
                                propToRender={'name'}
                                sx={{ mb: 1 }}
                            />
                        </div>
                        {doc ? (
                            <div
                                style={{
                                    width: '100%',
                                    backgroundColor: theme.palette.secondary.main,
                                    margin: '20px 0px',
                                    borderRadius: 10
                                }}
                                key={'item-price'}
                            >
                                <ItemPriceForm
                                    disabled={doc && !editToggled}
                                    doc={doc}
                                    itemPriceState={doc && !editToggled ? itemPrice : itemPriceState}
                                    setItemPriceState={setItemPriceState}
                                    priceLists={priceLists}
                                />
                            </div>
                        ) : null}
                    </>
                );
            case 'valuation_rate':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'valuation_rate'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.valuation_rate : formState.valuation_rate}
                            label={t('dashboard:valuationRate')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'is_purchase_item':
                return (
                    <div style={{ width: '50%' }} key={`${field.fieldname}`}>
                        <FormControlLabel
                            sx={{ marginLeft: '35%' }}
                            control={
                                <Checkbox
                                    checked={doc && !editToggled ? doc?.is_purchase_item === 1 : formState.is_purchase_item === 1}
                                    onChange={doc && !editToggled ? null : handleCheckChange}
                                    name={field.fieldname}
                                />
                            }
                            label={<Typography fontWeight={500}>{field.label}</Typography>}
                        />
                    </div>
                );
            case 'item_code':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'item_code'}
                            error={doc && !editToggled ? null : formErrState.item_code}
                            handleChange={(doc && !editToggled) || editToggled ? null : handleChange}
                            disabled={editToggled || (doc && !editToggled)}
                            value={doc && !editToggled ? doc.item_code : formState.item_code}
                            required
                            label={t('products:productCode')}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'item_name':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'item_name'}
                            error={doc && !editToggled ? null : formErrState.item_name}
                            handleChange={doc && !editToggled ? null : handleChange}
                            disabled={doc && !editToggled}
                            value={doc && !editToggled ? doc.item_name : formState.item_name}
                            required
                            label={t('products:productName')}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'item_group':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('products:productCategory')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc.item_group : formState.item_group}
                            name={'item_group'}
                            data={itemGroups}
                            error={doc && !editToggled ? null : formErrState?.item_group}
                            propToRender={'name'}
                            required
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'stock_uom':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('products:stockUOM')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.stock_uom : formState?.stock_uom}
                            name={'stock_uom'}
                            data={uoms}
                            propToRender={'name'}
                            required
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'description':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'description'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            disabled={doc && !editToggled}
                            value={doc && !editToggled ? doc?.description : formState?.description}
                            label={t('common:description')}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'brand':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            label={t('products:brand')}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc.brand : formState.brand}
                            name={'brand'}
                            data={brands}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'item_collection':
                return (
                    <>
                        <div style={{ width: '50%' }} key={field.fieldname}>
                            <SelectForm
                                fullwidth
                                label={t('products:collection')}
                                disabled={doc && !editToggled}
                                handleChange={doc && !editToggled ? null : handleSelectChange}
                                value={doc && !editToggled ? doc?.item_collection : formState?.item_collection}
                                name={'item_collection'}
                                data={itemCollections}
                                propToRender={'name'}
                                sx={{ mb: 1 }}
                            />
                        </div>
                        <div style={{ width: '50%' }} key={'has_variants'}>
                            {!isSimple && !toPrint ? (
                                <FormControl sx={{ width: '100%' }} required error={doc ? null : formState.attributes?.length == 0}>
                                    <Grid container item spacing={4} display={'flex'} alignItems={'center'}>
                                        <Grid item xs={4}>
                                            <FormLabel sx={{ display: 'flex', justifyContent: 'right' }}>Types variantes:</FormLabel>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Box
                                                borderRadius={2}
                                                bgcolor={doc ? theme.palette.secondary.main : 'Menu'}
                                                p={2}
                                                sx={{ overflowY: 'scroll', height: 150, width: '100%' }}
                                            >
                                                {itemAttributes?.map((name, i) => (
                                                    <Box height={35} key={i}>
                                                        <FormControlLabel
                                                            key={i}
                                                            label={name.name}
                                                            control={
                                                                <Checkbox
                                                                    key={i}
                                                                    name={name.name}
                                                                    checked={
                                                                        doc && !editToggled
                                                                            ? isExistInDoc(name.name, doc.attributes, 'attribute')
                                                                            : isExistInDoc(name.name, formState.attributes, 'attribute')
                                                                    }
                                                                    onChange={doc && !editToggled ? () => {} : handleAttributesChange}
                                                                />
                                                            }
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            ) : null}
                        </div>
                    </>
                );
            case 'item_defaults':
                return (
                    <>
                        <div style={{ width: '50%' }} key={`${field.fieldname}-company`}>
                            <SelectForm
                                fullwidth
                                label={t('crm:company')}
                                disabled={itemDefaults?.company}
                                handleChange={itemDefaults?.company ? null : handleItemDefaultsChange}
                                value={doc ? doc.item_defaults?.[0]?.company : itemDefaults?.company}
                                name={'company'}
                                data={companies}
                                error={!itemDefaults?.company ? true : false}
                                propToRender={'name'}
                                required
                                sx={{ mb: 1 }}
                            />
                        </div>
                        <div style={{ width: '50%' }} key={`${field.fieldname}-default_warehouse`}>
                            <SelectForm
                                fullwidth
                                label={t('products:defaultWarehouse')}
                                disabled={doc && !editToggled}
                                handleChange={doc && !editToggled ? null : handleItemDefaultsChange}
                                value={doc && !editToggled ? doc?.item_defaults?.[0]?.default_warehouse : itemDefaults?.default_warehouse}
                                name="default_warehouse"
                                data={warehouses}
                                propToRender={'name'}
                                sx={{ mb: 1 }}
                            />
                        </div>
                    </>
                );
            default:
                return null;
        }
    }
    return (
        <Box>
            <Box>
                {doc && doc?.variant_of && (
                    <InputTextField
                        fullwidth
                        name={'item_template'}
                        value={doc.variant_of}
                        label={t('products:variantOf')}
                        sx={{ mb: 1 }}
                    />
                )}
            </Box>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                {sections?.map((section) => (
                    <div key={section.id}>
                        <Accordion expanded={toPrint ? true : undefined}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Typography fontWeight={700} color={'primary'}>
                                    {t(section.label)}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {getFieldsBySection(section.id)?.map((field) => (
                                    <React.Fragment key={field.fieldname}>{renderField(field)}</React.Fragment>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}
            </div>
            {!toPrint && doc && (
                <div style={{ padding: '10px 0px' }}>
                    <Box width={'100%'}>
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                padding: theme.spacing(2)
                            }}
                            onClick={() => setOpenBarcodes(!openBarcodes)}
                        >
                            <div
                                style={{
                                    width: 120,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography variant="subtitle1" fontWeight={700}>
                                    Barcodes
                                </Typography>
                                <HorizontalSplitIcon sx={{ height: 30, objectFit: 'cover', transform: 'rotate(90deg)' }} />
                            </div>
                        </Paper>
                        {openBarcodes ? (
                            <div
                                style={{
                                    width: '100%',
                                    backgroundColor: theme.palette.secondary.main,
                                    margin: '20px 0px',
                                    borderRadius: 10
                                }}
                            >
                                <BarcodesForm
                                    disabled={doc && !editToggled}
                                    doc={doc}
                                    barcodesState={doc && !editToggled ? doc?.barcodes : barcodesState}
                                    setBarcodesState={setBarcodesState}
                                />
                            </div>
                        ) : null}
                    </Box>
                    <Box width={'100%'}>
                        {ItemsFiles.map((file, i) => (
                            <MyFileLink key={i} file={file} />
                        ))}
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                padding: theme.spacing(2)
                            }}
                            onClick={() => setOpenDropZone(!openDropZone)}
                        >
                            <div
                                style={{
                                    width: 120,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography variant="subtitle1" fontWeight={700}>
                                    {t('common:attachFile')}
                                </Typography>
                                <AttachFileTwoToneIcon sx={{ height: 30, objectFit: 'cover' }} />
                            </div>
                        </Paper>
                        {openDropZone && (
                            <Box width={'100%'} sx={{ padding: theme.spacing(2) }}>
                                <DropzoneArea
                                    filesLimit={5}
                                    dropzoneText="Déposer les fichiers ici"
                                    onChange={handleFilesChange}
                                    maxFileSize={5000000}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box width={'100%'}>
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                padding: theme.spacing(2)
                            }}
                            onClick={() => navigate(`/pim/product-view/${doc?.name}/channels`)}
                        >
                            <div
                                style={{
                                    width: 120,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography variant="subtitle1" fontWeight={700}>
                                    CHANNELS
                                </Typography>
                                <ArrowDropDownCircleTwoToneIcon sx={{ height: 30, objectFit: 'cover' }} />
                            </div>
                        </Paper>
                    </Box>
                </div>
            )}
            {renderSaveButton() && (
                <Box display={'flex'} mt={3} justifyContent="right">
                    <LoadingButton
                        loading={loadingButton}
                        variant="contained"
                        size="large"
                        color="blue"
                        disabled={(formErrState.item_code || formErrState.item_name || formErrState.item_group) && !doc}
                        onClick={() => handleCreate()}
                    >
                        {t('common:save')}
                    </LoadingButton>
                </Box>
            )}
        </Box>
    );
};

export default TemplateProdForm;
