import { Box, IconButton, Tooltip } from '@mui/material';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import CachedIcon from '@mui/icons-material/Cached';
import { useTranslation } from 'react-i18next';

function StockValue() {
    const { t } = useTranslation();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const [chartState, setChartState] = useState({
        height: 480,
        type: 'bar',
        options: {
            chart: {
                id: 'bar-chart',
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%'
                }
            },
            xaxis: {
                type: 'category',
                categories: []
            },
            legend: {
                show: true,
                fontSize: '14px',
                fontFamily: `'Quicksand', sans-serif`,
                position: 'bottom',
                offsetX: 20,
                labels: {
                    useSeriesColors: false
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 15,
                    vertical: 8
                }
            },
            fill: {
                type: 'solid'
            },
            dataLabels: {
                enabled: true
            },
            grid: {
                show: true
            }
        },
        series: [
            {
                name: 'Stock Value',
                data: []
            }
        ]
    });

    const {
        data: stockValueCharts,
        isPending: isStockValueChartsPending,
        refetch
    } = useFetch(`/api/get-warehouse-wise-stock-chart`, { chart_name: 'Warehouse wise Stock Value' }, 'POST');

    useEffect(() => {
        setChartState({
            ...chartState,
            series: [{ name: `Stock Value (${globalDefaults?.default_currency})`, data: stockValueCharts?.datasets?.[0]?.values }],
            options: { ...chartState?.options, xaxis: { type: 'category', categories: stockValueCharts?.labels } }
        });
    }, [stockValueCharts]);

    return (
        <MainCard
            title={
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Box color={'ActiveCaption'} display={'flex'} alignItems={'center'}>
                        {t('dashboard:stval')}
                    </Box>
                    <Tooltip title={t('dashboard:resync')}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                refetch({});
                            }}
                        >
                            <CachedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            }
            isWhite
            boxShadow
            divider
        >
            {isStockValueChartsPending ? 'Loading...' : <Chart type="bar" {...chartState} />}
        </MainCard>
    );
}

export default StockValue;
