import {
    Avatar,
    Button,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    OutlinedInput,
    Pagination,
    TextField,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import CommentIcon from '@mui/icons-material/Comment';
import { Box } from '@mui/system';
import { styled, useTheme } from '@mui/material/styles';
import { formatErpResponse } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { SetNotification } from 'store/services/api';
import DeleteIcon from '@mui/icons-material/Delete';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import Loader from 'ui-component/Loader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchIcon from '@mui/icons-material/Search';
import { GlobalfilterData } from 'utils/formatColumnsHeaders';
import { useEffect } from 'react';

const Reasons = ({
    isReasonsPending,
    reasons,
    refetch,
    loadingButton,
    setLoadingButton,
    handleReasonChange,
    reason,
    setReason,
    handleReasonInsert,
    doctype,
    title
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [page, setPage] = React.useState(1);
    const [filteredReason, setFilteredReason] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState('');

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDeleteReason = (reason) => {
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: doctype, name: reason?.name })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    refetch({});
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('crm:reasondeleted') }));
                    refetch({});
                }
            });
    };

    const handleSearchReason = (event) => {
        setSearchTerm(event.target.value);
        let data = GlobalfilterData(reasons, event.target.value.toLowerCase(), ['name']);
        data?.length ? setFilteredReason(data) : setFilteredReason([]);
    };

    useEffect(() => {
        if (filteredReason?.length == 0 && reasons?.length > 0 && searchTerm == '') {
            setFilteredReason(reasons);
        }
    }, [reasons]);

    if (isReasonsPending) return <Loader />;
    return (
        <MainCard
            border
            isWhite
            title={
                <Box p={-1} display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'} alignItems={'center'}>
                        <PsychologyAltIcon sx={{ mr: 1 }} /> {title}
                    </Box>
                    <Box>
                        <TextField
                            id="standard-helperText"
                            placeholder={t('settings:text-51')}
                            variant="standard"
                            value={searchTerm}
                            onChange={handleSearchReason}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            }
        >
            <form onSubmit={handleReasonInsert}>
                <OutlinedInput
                    placeholder={t('settings:text-52')}
                    endAdornment={
                        <LoadingButton
                            loading={loadingButton}
                            endIcon={<DoneIcon color={reason === '' ? 'disabled' : 'blue'} />}
                            variant="contained"
                            disabled={reason === ''}
                            type="submit"
                        >
                            {t('settings:text-53')}
                        </LoadingButton>
                    }
                    value={reason}
                    onChange={handleReasonChange}
                    sx={{ width: '100%' }}
                />
            </form>
            <Box sx={{ mt: 2 }}>
                <PerfectScrollbar style={{ overflowX: 'hidden' }}>
                    {filteredReason?.slice((page - 1) * 5, page * 5)?.map((reason, index) => (
                        <Box
                            key={index}
                            sx={{
                                bgcolor: theme.palette.grey[100],
                                borderRadius: 2,
                                p: 1,
                                mb: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                flexGrow: 1,
                                cursor: 'pointer',
                                alignItems: 'center'
                            }}
                        >
                            <Box width={'95%'}>
                                <Typography fontWeight={800} fontSize={13} color="black" sx={{ ml: 1 }}>
                                    <span style={{ color: theme.palette.blue.main, marginLeft: 2, fontWeight: 500 }}>{reason?.name}</span>
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                                <IconButton onClick={() => handleDeleteReason(reason)}>
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}

                    {Math.ceil(filteredReason?.length / 5) > 0 && (
                        <Box display="flex" justifyContent="center" m={4}>
                            <Pagination
                                color="primary"
                                count={Math.ceil(filteredReason?.length / 5)}
                                onChange={(e, newPage) => setPage(newPage)}
                                page={page}
                            />
                        </Box>
                    )}
                </PerfectScrollbar>
            </Box>
        </MainCard>
    );
};

export default Reasons;
