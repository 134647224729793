import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import MainCard from 'ui-component/cards/MainCard';
import { TreeView } from '@mui/x-tree-view';
import {
    Modal,
    Button,
    ButtonGroup,
    Typography,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Grid,
    IconButton,
    OutlinedInput,
    TextField,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import useFetch from 'hooks/useFetch';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTheme } from '@mui/styles';
import Loader from 'ui-component/Loader';
import LoadingButton from '@mui/lab/LoadingButton';
import { MinusSquare, PlusSquare, CloseSquare, StyledTreeItem } from 'ui-component/treeComponents';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    border: 'none'
};

const DepotTree = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const warehouseReqData = {
        doctype: 'Warehouse',
        fields: [
            '`tabWarehouse`.`name`',
            '`tabWarehouse`.`creation`',
            '`tabWarehouse`.`modified`',
            '`tabWarehouse`.`is_group`',
            '`tabWarehouse`.`company`',
            '`tabWarehouse`.`city`',
            '`tabWarehouse`.`warehouse_name`',
            '`tabWarehouse`.`parent_warehouse`',
            '`tabWarehouse`.`is_storage`'
        ],
        start: 0,
        page_length: 500
    };
    const customerReqData = {
        doctype: 'Customer',
        fields: [
            '`tabCustomer`.`name`',
            '`tabCustomer`.`docstatus`',
            '`tabCustomer`.`customer_group`',
            '`tabCustomer`.`territory`',
            '`tabCustomer`.`customer_name`',
            '`tabCustomer`.`image`',
            '`tabCustomer`.`customer_type`',
            '`tabCustomer`.`disabled`'
        ],
        start: 0,
        page_length: 500
    };
    const { data, isPending, refetch } = useFetch(`/api/get-list`, warehouseReqData, 'POST');
    const uniqueValues = [...new Set(data.map((option) => option.company))];
    const { data: customer, isPending: isCustomerPending } = useFetch(`/api/get-list`, customerReqData, 'POST');
    const [root, setRoot] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);
    const [isGroup, setIsGroup] = useState(false);
    const [state, setState] = React.useState({
        warehouse_name: '',
        default_customer: ''
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDelete = () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Warehouse', name: selectedRow })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ message: 'Dépôt supprimée avec succès', code: 'success' }));
                    handleClose();
                    refetch({});
                }
            });
    };

    const getRoot = (data) => {
        return data.filter((el) => el.parent_warehouse == '');
    };

    const getChildren = (parentName) => {
        return data.filter((el) => el.parent_warehouse == parentName && el.is_storage == 1);
    };

    const handleClick = (action) => {
        setAction(action);
        handleOpen();
    };

    const handleSelect = (e) => {
        setSelectedRow(e.target.textContent);
    };

    const handleDetails = (row) => {
        navigate(`/core/depots/${row.name}`);
    };

    useEffect(() => {
        if (Array.isArray(data)) {
            selectedOption != '' ? setRoot(getRoot(data?.filter((el) => el.company == selectedOption))) : setRoot(getRoot(data));
        }
    }, [data, open, selectedOption]);

    function getTreeItemLabel(row) {
        return (
            <Box sx={{ display: 'flex', p: 0.5, pr: 0, height: 40, alignItems: 'center' }}>
                <Typography
                    variant="h5"
                    onClick={(e) => handleSelect(e)}
                    sx={{ flexGrow: 1, fontWeight: selectedRow === row.name ? 600 : 500 }}
                >
                    {row.name}
                </Typography>
                {selectedRow === row.name && (
                    <ButtonGroup
                        sx={{ borderRadius: 10, border: 'none', boxShadow: 'none' }}
                        variant="contained"
                        size="small"
                        color="blue"
                        aria-label="outlined primary button group"
                    >
                        <Button
                            disabled={getRoot(data)?.[0]?.name === selectedRow}
                            color="blue"
                            sx={{ fontSize: 11 }}
                            onClick={() => handleDetails(row)}
                        >
                            {t('common:edit')}
                        </Button>
                        {row.is_group === 1 && (
                            <Button color="blue" sx={{ fontSize: 11 }} onClick={() => handleClick('add')}>
                                Ajouter enfant
                            </Button>
                        )}

                        <Button
                            disabled={getRoot(data)?.[0]?.name === selectedRow}
                            color="error"
                            sx={{ fontSize: 11 }}
                            onClick={() => handleClick('remove')}
                        >
                            {t('common:delete')}
                        </Button>
                    </ButtonGroup>
                )}
            </Box>
        );
    }

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Grid>
                {t('core:warehousetree')}
                <IconButton
                    aria-haspopup="true"
                    onClick={() => navigate('/core/depots')}
                    color="inherit"
                    aria-label="cart"
                    variant="rounded"
                    size="small"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: 'transparent !important',
                        color: theme.palette.primary.dark,
                        borderRadius: 50,
                        height: 20,
                        ml: 2,
                        mt: -0.5
                    }}
                >
                    <ViewListIcon
                        sx={{
                            height: 25
                        }}
                        color="blue"
                    />
                </IconButton>
            </Grid>
        </Box>
    );

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const filterCompany = data?.filter((el) => el.parent_warehouse == selectedRow);
    const companyName = filterCompany.map((ele) => ele.company);

    const newWarehouseData = {
        doctype: 'Warehouse',
        warehouse_name: state.warehouse_name,
        is_group: isGroup ? 1 : 0,
        default_customer: state.default_customer,
        parent: selectedRow,
        is_root: false,
        is_storage: 1,
        company: companyName[0] ?? companyName[0]
    };

    const handleAddWarehouseGroup = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/products/add-category`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(newWarehouseData)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ message: "L'entrepôt crée avec succès", code: 'success' }));
                    handleClose();
                    refetch({});
                }
            });
    };

    const addForm = (
        <Box sx={style}>
            <MainCard title={t('core:newwarehouse')}>
                <TextField
                    required
                    sx={{ width: '100%' }}
                    label={t('core:namewarehouse')}
                    id="outlined-size-small"
                    size="small"
                    name="warehouse_name"
                    value={state.warehouse_name}
                    onChange={handleChange}
                />
                <FormControlLabel
                    sx={{ mt: 2 }}
                    label={t('common:group')}
                    control={<Checkbox checked={isGroup} color="blue" onChange={() => setIsGroup(!isGroup)} />}
                />
                <Typography id="modal-modal-description" sx={{ fontSize: 11 }}>
                    {t('core:msg-one')}
                </Typography>
                <FormControl variant="outlined" fullWidth sx={{ width: '100%', mt: 3 }}>
                    <Select
                        name="default_customer"
                        value={state.default_customer}
                        onChange={handleChange}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                    >
                        {customer?.map((name) => (
                            <MenuItem key={name.name} value={name.name}>
                                {name.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    name="company"
                    value={companyName[0] ? companyName[0] : ''}
                    required
                    sx={{ width: '100%', mt: 3 }}
                    label={t('core:comp')}
                    id="outlined-size-small"
                    size="small"
                />
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <LoadingButton
                        onClick={handleAddWarehouseGroup}
                        loading={loadingButton}
                        disabled={state.warehouse_name === '' || state.default_customer === ''}
                        variant="contained"
                        color="blue"
                        size="large"
                    >
                        {t('common:save')}
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    const deleteConfirm = (
        <Box sx={style}>
            <MainCard title={'Confirmer'}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:deletePermanently')} <strong>{selectedRow}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Button color="blue" size="large" variant="outlined" sx={{ mr: 1 }} onClick={handleClose}>
                        Non
                    </Button>
                    <LoadingButton variant="contained" color="blue" size="large" onClick={handleDelete} loading={loadingButton}>
                        Oui
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    if (isPending && uniqueValues && isCustomerPending) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                <FormControl fullWidth>
                    <InputLabel id="demo-multiple-name-label">{t('core:comp')}</InputLabel>
                    <Select
                        input={<OutlinedInput label={t('core:comp')} />}
                        labelId="demo-multiple-name-label"
                        value={selectedOption}
                        onChange={handleSelectChange}
                    >
                        {uniqueValues.map((value) => {
                            const uniqueOption = data.find((option) => option.company === value);
                            return (
                                <MenuItem key={uniqueOption.company} value={uniqueOption.company}>
                                    {uniqueOption.company}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Box mt={3}>
                    <TreeView
                        defaultExpanded={['1']}
                        aria-label="file system navigator"
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<CloseSquare />}
                        sx={{ flexGrow: 1, overflowY: 'auto' }}
                    >
                        {root.map((row, i) => (
                            <StyledTreeItem key={i} nodeId={row.name} label={getTreeItemLabel(row)}>
                                {getChildren(row.name).map((subRows, idx) => (
                                    <StyledTreeItem key={idx} nodeId={subRows.name} label={getTreeItemLabel(subRows)}>
                                        {getChildren(subRows.name).map((xsRow, index) => (
                                            <StyledTreeItem key={index} nodeId={xsRow.name} label={getTreeItemLabel(xsRow)}>
                                                {getChildren(xsRow.name).map((xxsRow, ix) => (
                                                    <StyledTreeItem
                                                        key={ix}
                                                        nodeId={xxsRow.name}
                                                        label={getTreeItemLabel(xxsRow)}
                                                    ></StyledTreeItem>
                                                ))}
                                            </StyledTreeItem>
                                        ))}
                                    </StyledTreeItem>
                                ))}
                            </StyledTreeItem>
                        ))}
                    </TreeView>
                </Box>
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                {action == 'add' ? addForm : action == 'remove' ? deleteConfirm : <div>Error</div>}
            </Modal>
        </Box>
    );
};
export default DepotTree;
