import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextareaAutosize
} from '@mui/material';

import { useStyles } from 'views/utilities/Style';

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';
import { LoadingButton } from '@mui/lab';

const ItemChannelsForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [selectedChannelAtt, setSelectedChannelAtt] = useState(null);
    const [channelName, setChannelName] = useState('');
    const [selectedChannel, setSelectedChannel] = useState(null);
    const { data: channels, isChannelsPending } = useFetch(`/api/get-channels`, {}, 'POST');
    const [itemValues, setItemValues] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [loadingButton, setLoadingButton] = useState(false);

    const handleChange = (event) => {
        setChannelName(event.target.value);
        const index = channels.findIndex((chan) => event.target.value === chan?.name);
        if (index !== -1) {
            setSelectedChannel(channels[index]);
            channels[index]?.attributes && setSelectedChannelAtt(JSON.parse(channels[index]?.attributes));
            setIsLoading(true);
            fetch(`${process.env.REACT_APP_API_URI}/api/get-item-bychannel`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({
                    channel_name: event.target.value,
                    item_code: id
                })
            })
                .then((res) => {
                    setIsLoading(false);
                    return res.json();
                })
                .then((data) => {
                    if (data) {
                        setItemValues(data?.attribute_values);
                    }
                });
        }
    };

    const handleFormChange = (event) => {
        setItemValues({ ...itemValues, [event.target.name]: event.target.value });
    };

    const handleCheckChange = (event) => {
        setItemValues({ ...itemValues, [event.target.name]: event.target.checked });
    };

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            Configuring Item: {id} Channels
        </Box>
    );

    const handleClick = () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/channels-items`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                channel_name: channelName,
                item_code: id,
                attribute_values: itemValues
            })
        })
            .then((res) => {
                setLoadingButton(false);
                return res.json();
            })
            .then((data) => {
                if (data) {
                    // console.log(data);
                }
            });
    };

    if (isChannelsPending || isLoading) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                <Box p={5} sx={{ borderRadius: 2, bgcolor: 'Menu' }}>
                    <div style={{ width: '25%' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-name-label" required>
                                Channel
                            </InputLabel>
                            <Select
                                name={'name'}
                                labelId="demo-name-label"
                                id="demo-multiple-name"
                                onChange={handleChange}
                                value={channelName}
                                input={<OutlinedInput label={'Channel'} />}
                            >
                                {channels?.map((chan, index) => (
                                    <MenuItem key={`${chan?.name}-${index}`} value={chan?.name}>
                                        {chan?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {selectedChannel ? (
                        <>
                            <Box sx={{ padding: '30px' }}>
                                <Divider>
                                    <Chip label="Attributes" />
                                </Divider>
                            </Box>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 15, width: '50%' }}>
                                {selectedChannelAtt?.map((att, i) => {
                                    switch (att.type) {
                                        case 'Text':
                                            return (
                                                <FormControl fullWidth key={i}>
                                                    <InputLabel>{att.attName}</InputLabel>
                                                    <OutlinedInput
                                                        label={att.attName}
                                                        autoComplete="off"
                                                        placeholder={att.attName}
                                                        name={att.att}
                                                        value={itemValues?.[att.att] ?? ''}
                                                        onChange={handleFormChange}
                                                    />
                                                </FormControl>
                                            );
                                        case 'Check':
                                            return (
                                                <FormControlLabel
                                                    key={i}
                                                    label={att.attName}
                                                    control={
                                                        <Checkbox
                                                            key={att.attName}
                                                            name={att.att}
                                                            onChange={handleCheckChange}
                                                            checked={itemValues?.[att.att] ?? false}
                                                        />
                                                    }
                                                />
                                            );
                                        case 'Select':
                                            return (
                                                <FormControl fullWidth key={i}>
                                                    <InputLabel id="demo-name-label" required>
                                                        {att.attName}
                                                    </InputLabel>
                                                    <Select
                                                        name={att.att}
                                                        labelId="demo-name-label"
                                                        id="demo-multiple-name"
                                                        onChange={handleFormChange}
                                                        value={itemValues?.[att.att] ?? ''}
                                                        input={<OutlinedInput label={att.attName} />}
                                                        key={i}
                                                    >
                                                        {att?.options?.map((option, index) => (
                                                            <MenuItem key={index} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            );
                                        case 'Number':
                                            return (
                                                <FormControl fullWidth key={i}>
                                                    <InputLabel>{att.attName}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        label={att.attName}
                                                        autoComplete="off"
                                                        placeholder={att.attName}
                                                        name={att.att}
                                                        onChange={handleFormChange}
                                                        value={itemValues?.[att.att] ?? 0}
                                                    />
                                                </FormControl>
                                            );
                                        case 'TextArea':
                                            return (
                                                <div key={i}>
                                                    <InputLabel sx={{ fontSize: 11 }}>{att.attName}:</InputLabel>
                                                    <TextareaAutosize
                                                        key={i}
                                                        aria-label={att.attName}
                                                        name={att.att}
                                                        onChange={handleFormChange}
                                                        value={itemValues?.[att.att] ?? ''}
                                                        minRows={3}
                                                    />
                                                </div>
                                            );
                                        default:
                                            return null;
                                    }
                                })}
                            </div>
                        </>
                    ) : null}
                    <Box width={'100%'} mt={3} display={'flex'} justifyContent={'flex-end'}>
                        <LoadingButton loading={loadingButton} size="large" variant="contained" color="blue" onClick={handleClick}>
                            Save
                        </LoadingButton>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );
};

export default ItemChannelsForm;
