import { Box, Button, Grid } from '@mui/material';
import useFetch from 'hooks/useFetch';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import DataRender from 'ui-component/tables/DataRender';
import { formatErpResponse } from 'utils/utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function UOMList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [selectionModel, setSelectionModel] = useState([]);

    const UOMReqData = {
        doctype: 'UOM',
        fields: ['`tabUOM`.`name`', '`tabUOM`.`uom_name`', '`tabUOM`.`enabled`', '`tabUOM`.`modified`'],
        order_by: '`tabUOM`.`modified` desc',
        start: 0,
        page_length: 500
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, UOMReqData, 'POST');

    const handleEdit = (row) => {
        navigate(`/pim/uom/${row}`);
    };

    const handleDelete = (row) => {
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'UOM', name: row[0] })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:uom-del') }));
                    refetch({});
                }
            });
    };

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {t('common:uoms')}
            <Box>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            onClick={() => navigate('/pim/uom')}
                            sx={{ marginTop: -3 }}
                        >
                            {t('common:uom-add')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
    return (
        <div>
            <DataRender
                refetch={refetch}
                data={data}
                loading={isPending}
                selectionModel={selectionModel}
                title={title}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        </div>
    );
}

export default UOMList;
