// assets
import { IconHeartbeat } from '@tabler/icons';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { ROLE } from '../configs/constants';
// constant
const icons = {
    FavoriteBorderOutlinedIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    role: [ROLE.ADMIN],
    children: [
        {
            id: 'core',
            title: 'core:core',
            type: 'collapse',
            icon: icons.FavoriteBorderOutlinedIcon,
            children: [
                {
                    id: 'core-dashboard',
                    title: 'common:dash',
                    type: 'item',
                    url: '/core/dashboard'
                },
                {
                    id: 'company',
                    title: 'core:companies',
                    type: 'item',
                    url: '/core/company'
                },
                {
                    id: 'magasins',
                    title: 'core:stores',
                    type: 'item',
                    url: '/core/magasins'
                },
                {
                    id: 'depots',
                    title: 'core:warehouses',
                    type: 'item',
                    url: '/core/depots'
                },
                {
                    id: 'pos',
                    title: 'core:pos',
                    type: 'item',
                    url: '/core/pos'
                },
                {
                    id: 'mode-of-payment',
                    title: 'core:pm',
                    type: 'item',
                    url: '/core/mode-of-payment'
                },
                {
                    id: 'currencies',
                    title: 'core:crr',
                    type: 'item',
                    url: '/core/currencies-list'
                },
                {
                    id: 'taxes',
                    title: 'Taxes',
                    type: 'item',
                    url: '/core/taxes-list'
                },
                {
                    id: 'discount',
                    title: 'core:dcnt',
                    type: 'item',
                    url: '/core/discount'
                },
                {
                    id: 'supplier',
                    title: 'core:supplier',
                    type: 'item',
                    url: '/core/supplier'
                }
            ]
        }
    ]
};

export default pages;
