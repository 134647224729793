import React, { useState } from 'react';
import { Grid, Typography, Box, IconButton, Tooltip, Modal, Button } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from 'views/utilities/Style';
import useGetAxios from 'hooks/useGetAxios';
import Loader from 'ui-component/Loader';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import useFetch from 'hooks/useFetch';
import DetailsTables from 'ui-component/simpleTable/DetailsTables';
import ArticleIcon from '@mui/icons-material/Article';
import { useTranslation } from 'react-i18next';
import SupplierDetailsCard from './SupplierDetailsCard';
import { useTheme } from '@mui/material/styles';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CloseIcon from '@mui/icons-material/Close';
import { modalStyle } from 'views/utilities/ModalStyle';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const SupplierForm = () => {
    const theme = useTheme();
    const { id } = useParams();
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { data: doc, isPending, refetch } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Supplier&name=${id}`);

    const { data: reqForQuotation, isRFQPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Request for Quotation',
            fields: [
                '`tabRequest for Quotation`.`name`',
                '`tabRequest for Quotation`.`company`',
                '`tabRequest for Quotation`.`transaction_date`',
                '`tabRequest for Quotation`.`status`'
            ],
            filters: [['Request for Quotation Supplier', 'supplier', '=', id]],
            order_by: '`tabRequest for Quotation`.`modified` desc'
        },
        'POST'
    );

    const { data: supplierQuotation, isSupplierQuotPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Supplier Quotation',
            fields: [
                '`tabSupplier Quotation`.`name`',
                '`tabSupplier Quotation`.`transaction_date`',
                '`tabSupplier Quotation`.`total_taxes_and_charges`',
                '`tabSupplier Quotation`.`grand_total`',
                '`tabSupplier Quotation`.`status`',
                '`tabSupplier Quotation`.`currency`'
            ],
            filters: [['Supplier Quotation', 'supplier_name', '=', id]],
            order_by: '`tabSupplier Quotation`.`modified` desc'
        },
        'POST'
    );

    const { data: purchaseOrder, isPOPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Purchase Order',
            fields: [
                '`tabPurchase Order`.`name`',
                '`tabPurchase Order`.`transaction_date`',
                '`tabPurchase Order`.`total_taxes_and_charges`',
                '`tabPurchase Order`.`grand_total`',
                '`tabPurchase Order`.`status`',
                '`tabPurchase Order`.`currency`'
            ],
            filters: [['Purchase Order', 'supplier', '=', id]],
            order_by: '`tabPurchase Order`.`modified` desc'
        },
        'POST'
    );

    const { data: purchaseReceipt, isPRPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Purchase Receipt',
            fields: [
                '`tabPurchase Receipt`.`name`',
                '`tabPurchase Receipt`.`posting_date`',
                '`tabPurchase Receipt`.`total_taxes_and_charges`',
                '`tabPurchase Receipt`.`grand_total`',
                '`tabPurchase Receipt`.`currency`',
                '`tabPurchase Receipt`.`status`'
            ],
            filters: [['Purchase Receipt', 'supplier', '=', id]],
            order_by: '`tabPurchase Receipt`.`modified` desc'
        },
        'POST'
    );

    const { data: purchaseInvoice, isPIPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Purchase Invoice',
            fields: [
                '`tabPurchase Invoice`.`name`',
                '`tabPurchase Invoice`.`posting_date`',
                '`tabPurchase Invoice`.`total_taxes_and_charges`',
                '`tabPurchase Invoice`.`grand_total`',
                '`tabPurchase Invoice`.`currency`',
                '`tabPurchase Invoice`.`status`'
            ],
            filters: [['Purchase Invoice', 'supplier', '=', id]],
            order_by: '`tabPurchase Invoice`.`modified` desc'
        },
        'POST'
    );

    const { data: payementEntry, isPEPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Payment Entry',
            fields: [
                '`tabPayment Entry`.`name`',
                '`tabPayment Entry`.`payment_type`',
                '`tabPayment Entry`.`posting_date`',
                '`tabPayment Entry`.`mode_of_payment`',
                '`tabPayment Entry`.`paid_amount`',
                '`tabPayment Entry`.`status`'
            ],
            filters: [['Payment Entry', 'party_name', '=', id]],
            order_by: '`tabPayment Entry`.`modified` desc'
        },
        'POST'
    );

    const handleAddCatalog = () => {
        navigate(`/core/supplier-catalog/${id}`);
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box p={-1} display={'flex'} flexDirection={'column'}>
                <ArrowBackIcon
                    className={classes.backLink}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
                <Box display={'flex'}>
                    {`${t('core:supplierDetails')}: ${doc?.supplier_name}`}
                    <Tooltip title="Show Contact" arrow>
                        <IconButton
                            onClick={handleOpen}
                            aria-haspopup="true"
                            color="inherit"
                            aria-label="cart"
                            variant="rounded"
                            size="small"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: 'transparent !important',
                                color: theme.palette.primary.dark,
                                borderRadius: 50,
                                height: 20,
                                ml: 2,
                                mt: -0.5
                            }}
                        >
                            <ContactPageIcon
                                sx={{
                                    marginTop: 2
                                }}
                                color="blue"
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box>
                <Button startIcon={<LocalShippingIcon />} variant="outlined" color="blue" sx={{ ml: 1 }} onClick={handleAddCatalog}>
                    {t('core:supplierCatalog')}
                </Button>
            </Box>
        </Box>
    );

    const contactTitle = (
        <Box p={-1} display="flex" justifyContent="space-between">
            <Grid container spacing={5}>
                <Grid item xs={6}>
                    {t('core:contactList')}
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                    <IconButton
                        onClick={handleClose}
                        aria-haspopup="true"
                        color="inherit"
                        aria-label="cart"
                        variant="rounded"
                        size="small"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: 'transparent !important',
                            color: theme.palette.primary.dark,
                            borderRadius: 50,
                            height: 20,
                            ml: 2,
                            mt: -0.5
                        }}
                    >
                        <CloseIcon
                            sx={{
                                marginTop: 2
                            }}
                            color="blue"
                        />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    );

    const contactList = (
        <Box sx={modalStyle}>
            <MainCard divider title={contactTitle}>
                <PerfectScrollbar style={{ overflow: 'scroll', height: 300 }}>
                    {doc?.__onload?.contact_list &&
                        doc?.__onload?.contact_list.map((p, i) => (
                            <Box key={i}>
                                <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                                    Contact {i + 1}:
                                </Typography>
                                <Typography variant="body1" className={classes.text}>
                                    <strong>{t('core:firstLastName')}</strong> :{p?.first_name} {p?.last_name}
                                </Typography>
                                {p?.email_ids.length !== 0 && (
                                    <Typography variant="body1" className={classes.text}>
                                        <strong>Email</strong> :{' '}
                                        {p?.email_ids.map((email, index) => (
                                            <span key={index}>
                                                {index > 0 && ', '}
                                                {email.email_id} ,{p?.email_id}
                                            </span>
                                        ))}
                                    </Typography>
                                )}

                                {p?.phone_nos.length !== 0 && (
                                    <Typography variant="body1" className={classes.text}>
                                        <strong>{t('crm:phonenum')}</strong> :{' '}
                                        {p?.phone_nos.map((phone, index) => (
                                            <span key={index}>
                                                {index > 0 && ', '}
                                                {phone.phone} ,{p?.mobile_no}
                                            </span>
                                        ))}
                                    </Typography>
                                )}
                            </Box>
                        ))}
                </PerfectScrollbar>
            </MainCard>
        </Box>
    );

    if (id && isPending) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                <Grid container direction="row" spacing={2} display="flex">
                    <Grid item xs={12} md={4}>
                        <div>
                            <SupplierDetailsCard refetch={refetch} doc={doc} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box sx={{ width: '100%' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons>
                                <Tab label={t('core:requestforQuotation')} {...a11yProps(0)} />
                                <Tab label={t('core:supplierQuotation')} {...a11yProps(1)} />
                                <Tab label={t('core:purchaseOrder')} {...a11yProps(2)} />
                                <Tab label={t('core:purchaseReceipt')} {...a11yProps(3)} />
                                <Tab label={t('core:purchaseInvoice')} {...a11yProps(4)} />
                                <Tab label={t('core:paymentEntry')} {...a11yProps(4)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <DetailsTables
                                    data={reqForQuotation}
                                    loading={isRFQPending}
                                    title={
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ArticleIcon sx={{ mr: 1 }} /> {t('core:requestforQuotation')}
                                        </Box>
                                    }
                                    isSupplier
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <DetailsTables
                                    data={supplierQuotation}
                                    loading={isSupplierQuotPending}
                                    title={
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ArticleIcon sx={{ mr: 1 }} /> {t('core:supplierQuotation')}
                                        </Box>
                                    }
                                    isSupplier
                                />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <DetailsTables
                                    data={purchaseOrder}
                                    loading={isPOPending}
                                    title={
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ArticleIcon sx={{ mr: 1 }} /> {t('core:purchaseOrder')}
                                        </Box>
                                    }
                                    isSupplier
                                />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <DetailsTables
                                    data={purchaseReceipt}
                                    loading={isPRPending}
                                    title={
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ArticleIcon sx={{ mr: 1 }} /> {t('core:purchaseReceipt')}
                                        </Box>
                                    }
                                    isSupplier
                                />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <DetailsTables
                                    data={purchaseInvoice}
                                    loading={isPIPending}
                                    title={
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ArticleIcon sx={{ mr: 1 }} />
                                            {t('core:purchaseInvoice')}
                                        </Box>
                                    }
                                    isSupplier
                                />
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <DetailsTables
                                    data={payementEntry}
                                    loading={isPEPending}
                                    title={
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ArticleIcon sx={{ mr: 1 }} /> {t('core:paymentEntry')}
                                        </Box>
                                    }
                                    isSupplier
                                />
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                {contactList}
            </Modal>
        </Box>
    );
};

export default SupplierForm;
