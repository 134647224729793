// assets
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    SettingsOutlinedIcon
};

// ==============================|| USERS MENU ITEMS ||============================== //

const settings = {
    id: 'settings',
    type: 'group',
    role: [ROLE.ADMIN],
    children: [
        {
            id: 'settings',
            title: 'crm:settings',
            type: 'item',
            url: '/global-settings',
            icon: icons.SettingsOutlinedIcon
        }
    ]
};

export default settings;
