// assets
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    StorefrontOutlinedIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const sales = {
    id: 'sales',
    title: 'common:sales',
    role: [ROLE.SALES, ROLE.ADMIN],
    caption: 'Gestion des informations sur les articles',
    type: 'group',
    children: [
        {
            id: 'sales',
            title: 'common:sales',
            type: 'collapse',
            icon: icons.StorefrontOutlinedIcon,
            children: [
                // {
                //     id: 'Dashboard',
                //     title: 'common:dash',
                //     type: 'item',
                //     url: '/sales/dashboard'
                // },
                {
                    id: 'stock-view',
                    title: 'common:prdcts',
                    type: 'item',
                    url: '/sales/stock-view'
                },
                {
                    id: 'quotation',
                    title: 'Devis',
                    type: 'item',
                    url: '/sales/quotation-list'
                },
                {
                    id: 'sales-order',
                    title: 'crm:salesorder',
                    type: 'item',
                    url: '/sales/sales-order-list'
                },
                {
                    id: 'deposit-invoice',
                    title: 'crm:depositinv',
                    type: 'item',
                    url: '/sales/deposit-invoice-list'
                },
                {
                    id: 'tax-invoice',
                    title: 'crm:salesinv',
                    type: 'item',
                    url: '/sales/tax-invoice-list'
                },
                {
                    id: 'delivery-notes',
                    title: 'crm:deliverynote',
                    type: 'item',
                    url: '/sales/delivery-notes'
                },
                {
                    id: 'stores',
                    title: 'magasin',
                    type: 'item',
                    url: '/sales/stores'
                },
                {
                    id: 'customer',
                    title: 'crm:customers',
                    type: 'item',
                    url: '/crm/customer'
                }
            ]
        }
    ]
};

export default sales;
