//react
import React, { useState } from 'react';
//component imports
import DataRender from 'ui-component/tables/DataRender';
import MainCard from 'ui-component/cards/MainCard';
import useFetch from 'hooks/useFetch';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';

//mui
import { Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

//styles
import { useTheme } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { modalStyle } from 'views/utilities/ModalStyle';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const DepotsList = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const [selectionModel, setSelectionModel] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const warehouseReqData = {
        doctype: 'Warehouse',
        fields: ['`tabWarehouse`.`creation`', '`tabWarehouse`.`name`', '`tabWarehouse`.`city`', '`tabWarehouse`.`company`'],
        filters: [['Warehouse', 'is_storage', '=', 1]],
        order_by: '`tabWarehouse`.`modified` desc'
    };
    const { data, isPending, refetch } = useFetch(`/api/get-list`, warehouseReqData, 'POST');

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Warehouse', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ message: "L'entrepôt  supprimée avec succès", code: 'success' }));
                    handleClose();
                    refetch({});
                }
            });
    };

    const handleEdit = (row) => {
        navigate(`/core/depots/${row}`);
    };

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:deletePermanently')} <strong>{selectionModel}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    Non
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    Oui
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Grid>
                {t('core:warehouses')}
                <IconButton
                    aria-haspopup="true"
                    onClick={() => navigate('/core/depots-tree')}
                    color="inherit"
                    aria-label="cart"
                    variant="rounded"
                    size="small"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: 'transparent !important',
                        color: theme.palette.primary.dark,
                        borderRadius: 50,
                        height: 20,
                        ml: 2,
                        mt: -0.5
                    }}
                >
                    <AccountTreeIcon
                        sx={{
                            height: 20
                        }}
                        color="blue"
                    />
                </IconButton>
            </Grid>
            <Box>
                <Grid container spacing={1}>
                    {false && (
                        <>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button variant="outlined" color="error" onClick={handleOpen} sx={{ marginTop: -3 }}>
                                        {t('common:delete')}
                                    </Button>
                                )}
                            </Grid>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button
                                        variant="outlined"
                                        color="blue"
                                        sx={{ marginTop: -3 }}
                                        onClick={() => handleEdit(selectionModel)}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                )}
                            </Grid>
                        </>
                    )}
                    <Grid item>
                        <Button variant="outlined" color="blue" onClick={() => navigate('/core/new-depots')} sx={{ marginTop: -3 }}>
                            <AddIcon /> {t('core:addwarehouse')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

    return (
        <div>
            <DataRender
                refetch={refetch}
                data={data}
                loading={isPending}
                selectionModel={selectionModel}
                title={title}
                setSelectionModel={setSelectionModel}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
            />
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
        </div>
    );
};

export default DepotsList;
