import axios from 'axios';
import { useState, useEffect } from 'react';

const useReportQuery = (url, reqData) => {
    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        setIsPending(true);
        return axios({
            method: 'post',
            url: url,
            data: reqData,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem('user'))?.token
            }
        })
            .then(({ data }) => {
                setData(data?.message);
                setIsPending(false);
                setError(null);
            })
            .catch((err) => {
                setIsPending(false);
                setError(err.message);
            });
    };

    useEffect(() => {
        fetchData();
    }, [url, reqData]);

    return { data, isPending, error, refetch: fetchData };
};

export default useReportQuery;
