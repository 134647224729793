import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import MainCard from 'ui-component/cards/MainCard';
import { TreeView } from '@mui/x-tree-view';
import {
    Button,
    ButtonGroup,
    Typography,
    Modal,
    FormControlLabel,
    Checkbox,
    TextField,
    Grid,
    IconButton,
    Paper,
    FormControl,
    OutlinedInput,
    InputAdornment
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import useFetch from 'hooks/useFetch';
import ViewListIcon from '@mui/icons-material/ViewList';
import { styled, useTheme } from '@mui/styles';
import Loader from 'ui-component/Loader';
import { MinusSquare, PlusSquare, CloseSquare, StyledTreeItem } from 'ui-component/treeComponents';
import { modalStyle } from 'views/utilities/ModalStyle';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { formatErpResponse } from 'utils/utils';
import { shouldForwardProp } from '@mui/system';
import { IconSearch } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
/* import { FilterDataTree } from 'utils/formatColumnsHeaders'; */

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    border: 'none'
};
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    padding: theme.spacing(0.1, 1, 0.1),
    textAlign: 'center',
    color: '#0766FF',
    border: `1px solid #0766FF`,
    marginTop: -3,
    marginLeft: 10,
    borderRadius: 10
}));
const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: '100%',
    height: 50,
    borderRadius: 10,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: '#fff'
    }
}));
const ItemGroupTree = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const itemGroupsReqData = {
        doctype: 'Item Group',
        fields: [
            '`tabItem Group`.`name`',
            '`tabItem Group`.`owner`',
            '`tabItem Group`.`creation`',
            '`tabItem Group`.`modified`',
            '`tabItem Group`.`modified_by`',
            '`tabItem Group`.`item_group_name`',
            '`tabItem Group`.`parent_item_group`',
            '`tabItem Group`.`is_group`',
            '`tabItem Group`.`image`'
        ],
        start: 0,
        page_length: 500
    };

    const { data, isPending } = useFetch(`/api/get-list`, itemGroupsReqData, 'POST');
    const [root, setRoot] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [searchvalue, setSearchvalue] = useState('');
    const [isGroup, setIsGroup] = useState(false);
    const [itemGroupName, setItemGroupName] = useState('');
    const handleChange = (e) => setItemGroupName(e.target.value);
    const [loadingButton, setLoadingButton] = useState(false);
    const [filtreddata, setFiltreddata] = useState([]);
    const [newName, setNewName] = useState('');
    const handleChangeNewName = (e) => setNewName(e.target.value);
    const handleSearch = async (event) => {
        setSearchvalue(event.target.value);
        const items = await search(data, event.target.value);
        setFiltreddata(items);
    };
    const newItemGroupData = {
        doctype: 'Item Group',
        is_group: isGroup ? 1 : 0,
        item_group_name: itemGroupName,
        parent: selectedRow,
        is_root: false
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleAddItemGroup = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/products/add-category`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(newItemGroupData)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Catégorie créée avec succès' }));
                    handleClose();
                }
            });
    };

    const handleDelete = () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Item Group', name: selectedRow })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'La catégorie a été supprimée avec succès' }));
                    handleClose();
                }
            });
    };

    const renameItemGroupData = {
        doctype: 'Item Group',
        docname: selectedRow,
        name: newName,
        enqueue: true,
        merge: 0
    };

    const handleRename = () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/rename-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(renameItemGroupData)
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                dispatch(SetNotification({ code: 'success', message: 'Catégorie renommée avec succès' }));
                handleClose();
            });
    };

    const addForm = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Nouvelle catégorie'}>
                <FormControlLabel
                    sx={{ mt: -2 }}
                    label="Groupe"
                    control={<Checkbox checked={isGroup} color="blue" onChange={() => setIsGroup(!isGroup)} />}
                />
                <Typography id="modal-modal-description" sx={{ fontSize: 11 }}>
                    {t('products:msg1')}
                </Typography>
                <TextField
                    onChange={handleChange}
                    value={itemGroupName}
                    required
                    sx={{ width: '100%', mt: 3 }}
                    label={t('products:categoryName')}
                    id="outlined-size-small"
                    size="small"
                />
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <LoadingButton
                        disabled={itemGroupName === ''}
                        onClick={handleAddItemGroup}
                        variant="contained"
                        color="blue"
                        size="large"
                        loading={loadingButton}
                    >
                        {t('common:create')}
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    const renameForm = (
        <Box sx={modalStyle}>
            <MainCard divider title={`Renommer "${selectedRow}"`}>
                <TextField
                    onChange={handleChangeNewName}
                    value={newName}
                    required
                    sx={{ width: '100%' }}
                    label="Nouveau nom de catégorie"
                    id="outlined-size-small"
                    size="small"
                />
                <FormControlLabel
                    sx={{ mt: 2 }}
                    label="Fusionner avec l'existant (cette opération ne peut être annulée)."
                    control={<Checkbox checked={false} />}
                />
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <LoadingButton variant="contained" color="blue" size="large" disabled={newName === ''} onClick={handleRename}>
                        Renommer
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:deletePermanently')} <strong>{selectedRow}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Button color="blue" size="large" variant="outlined" sx={{ mr: 1 }} onClick={handleClose}>
                        Non
                    </Button>
                    <LoadingButton variant="contained" color="blue" size="large" onClick={handleDelete} loading={loadingButton}>
                        Oui
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    const getRoot = (dataroot) => {
        return dataroot.filter((el) => el.parent_item_group == '');
    };

    const getChildren = (parentName) => {
        return !searchvalue
            ? data.filter((el) => el.parent_item_group == parentName)
            : filtreddata.filter((el) => el.parent_item_group == parentName);
    };
    const search = (items, term) => {
        return items.reduce((acc, item) => {
            if (contains(item.name, term)) {
                acc.push(item);
            } else if (getChildren(item.name) && getChildren(item.name).length > 0) {
                let newItems = search(getChildren(item.name), term);
                if (newItems && newItems.length > 0) {
                    acc.push(item);
                }
            }
            return acc;
        }, []);
    };

    const contains = (text, term) => {
        return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    };

    const handleClick = (action) => {
        setAction(action);
        handleOpen();
    };

    const handleDetails = (row) => {
        navigate(`/pim/category-view/${row.name}`);
    };

    const handleSelect = (e) => {
        setSelectedRow(e.target.textContent);
    };

    useEffect(() => {
        if (data) {
            if (searchvalue && filtreddata) {
                setRoot(getRoot(filtreddata));
            } else {
                setRoot(getRoot(data));
            }
        }
    }, [data, open, filtreddata]);

    function getTreeItemLabel(row) {
        return (
            <Box sx={{ display: 'flex', p: 0.5, pr: 0, height: 40, alignItems: 'center' }}>
                <Typography
                    variant="h5"
                    onClick={(e) => handleSelect(e)}
                    sx={{
                        flexGrow: root?.length > 0 && root[0]?.name === row.name ? 0 : 1,
                        fontWeight: selectedRow === row.name ? 600 : 500
                    }}
                >
                    {row.name}
                </Typography>
                {root?.length > 0 && root[0]?.name === row.name && (
                    <>
                        {'   '}
                        <Item>
                            <Typography color={'#0766FF'} variant="h4" fontWeight={500}>
                                {data.filter((el) => el.is_group == 1).length}
                                {/* {' trees'} */}
                            </Typography>
                        </Item>
                        <Box
                            sx={{
                                flexGrow: 1
                            }}
                        >
                            {' '}
                        </Box>
                    </>
                )}
                {selectedRow === row.name && (
                    <ButtonGroup
                        sx={{ borderRadius: 10, border: 'none', boxShadow: 'none' }}
                        variant="contained"
                        size="small"
                        color="blue"
                        aria-label="outlined primary button group"
                    >
                        <Button
                            disabled={getRoot(data)?.[0]?.name === selectedRow}
                            color="blue"
                            sx={{ fontSize: 11 }}
                            onClick={() => handleDetails(row)}
                        >
                            {t('common:edit')}
                        </Button>
                        {row.is_group === 1 && (
                            <Button color="blue" sx={{ fontSize: 11 }} onClick={() => handleClick('add')}>
                                Ajouter enfant
                            </Button>
                        )}
                        <Button
                            disabled={getRoot(data)?.[0]?.name === selectedRow}
                            color="blue"
                            sx={{ fontSize: 11 }}
                            onClick={() => handleClick('rename')}
                        >
                            Renommer
                        </Button>
                        <Button
                            disabled={getRoot(data)?.[0]?.name === selectedRow}
                            color="error"
                            sx={{ fontSize: 11 }}
                            onClick={() => handleClick('remove')}
                        >
                            {t('common:delete')}
                        </Button>
                    </ButtonGroup>
                )}
            </Box>
        );
    }

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Grid>
                {'Arbre de Catégories'}
                <IconButton
                    aria-haspopup="true"
                    onClick={() => navigate('/pim/products-category-list-view')}
                    color="inherit"
                    aria-label="cart"
                    variant="rounded"
                    size="small"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: 'transparent !important',
                        color: theme.palette.primary.dark,
                        borderRadius: 50,
                        height: 20,
                        ml: 2,
                        mt: -0.5
                    }}
                >
                    <ViewListIcon
                        sx={{
                            height: 25
                        }}
                        color="blue"
                    />
                </IconButton>
            </Grid>
            <Box>
                <Box display={'flex'} justifyContent={'right'}>
                    <FormControl variant="outlined">
                        <OutlineInputStyle
                            id="input-search-header"
                            value={searchvalue}
                            placeholder="Search ..."
                            onChange={handleSearch}
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={1.5} size="1rem" color={theme.palette.blue.main} />
                                </InputAdornment>
                            }
                            aria-describedby="search-helper-text"
                            inputProps={{ 'aria-label': 'weight' }}
                        />
                    </FormControl>
                </Box>
            </Box>
        </Box>
    );

    if (isPending) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                <Box>
                    <TreeView
                        defaultExpanded={['1']}
                        aria-label="file system navigator"
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<CloseSquare />}
                        sx={{ flexGrow: 1, overflowY: 'auto' }}
                    >
                        {root.map((row, i) => (
                            <StyledTreeItem key={i} nodeId={row.name} label={getTreeItemLabel(row)}>
                                {getChildren(row.name).map((subRows, idx) => (
                                    <StyledTreeItem key={idx} nodeId={subRows.name} label={getTreeItemLabel(subRows)}>
                                        {getChildren(subRows.name).map((xsRow, index) => (
                                            <StyledTreeItem key={index} nodeId={xsRow.name} label={getTreeItemLabel(xsRow)}>
                                                {getChildren(xsRow.name).map((xxsRow, ix) => (
                                                    <StyledTreeItem
                                                        key={ix}
                                                        nodeId={xxsRow.name}
                                                        label={getTreeItemLabel(xxsRow)}
                                                    ></StyledTreeItem>
                                                ))}
                                            </StyledTreeItem>
                                        ))}
                                    </StyledTreeItem>
                                ))}
                            </StyledTreeItem>
                        ))}
                    </TreeView>
                </Box>
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                {action == 'add' ? addForm : action == 'remove' ? deleteConfirm : action == 'rename' ? renameForm : <div>ERROR</div>}
            </Modal>
        </Box>
    );
};
export default ItemGroupTree;
