// assets
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    GroupOutlinedIcon
};

// ==============================|| USERS MENU ITEMS ||============================== //

const users = {
    id: 'users',
    type: 'group',
    role: [ROLE.ADMIN, ROLE.CASHIER],
    children: [
        {
            id: 'user',
            title: 'users:users',
            type: 'collapse',
            icon: icons.GroupOutlinedIcon,
            children: [
                {
                    id: 'roles',
                    title: 'users:roleMenu',
                    type: 'item',
                    url: '/core/roles'
                },
                {
                    id: 'profiles',
                    title: 'users:Profil',
                    type: 'item',
                    url: '/core/profiles'
                },
                {
                    id: 'user-list-view',
                    title: 'users:users',
                    type: 'item',
                    url: '/core/user-list-view'
                },
                {
                    id: 'connected-user',
                    title: 'users:ConnectedUser',
                    type: 'item',
                    url: '/core/connected-user'
                },
                {
                    id: 'vendeurs',
                    title: 'core:sp',
                    type: 'item',
                    url: '/core/vendeurs'
                }
            ]
        }
    ]
};

export default users;
