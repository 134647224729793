// assets
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    CategoryOutlinedIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pim = {
    id: 'pim',
    title: 'PIM',
    caption: 'Gestion des informations sur les articles',
    type: 'group',
    role: [ROLE.ADMIN, ROLE.CASHIER],
    children: [
        {
            id: 'PIM',
            title: 'PIM',
            type: 'collapse',
            icon: icons.CategoryOutlinedIcon,
            children: [
                {
                    id: 'pim-dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/pim/dashboard'
                },
                {
                    id: 'products',
                    title: 'common:prdct',
                    type: 'item',
                    url: '/pim/products-view'
                },
                {
                    id: 'products-category',
                    title: 'common:prod-cat',
                    type: 'item',
                    url: '/pim/products-category-list-view'
                },
                {
                    id: 'canaux',
                    title: 'common:canals',
                    type: 'item',
                    url: '/pim/canaux'
                },
                {
                    id: 'product-attributes',
                    title: 'common:tdv',
                    type: 'item',
                    url: '/pim/product-attributes'
                },
                {
                    id: 'customize-form',
                    title: 'common:tatt',
                    type: 'item',
                    url: '/pim/customize'
                },
                {
                    id: 'product-collections',
                    title: 'common:coll',
                    type: 'item',
                    url: '/pim/product-collections'
                },
                {
                    id: 'product-bundles',
                    title: 'common:ep',
                    type: 'item',
                    url: '/pim/product-bundles'
                },
                {
                    id: 'product-files',
                    title: 'common:ass',
                    type: 'item',
                    url: '/pim/product-files'
                },
                {
                    id: 'uoms',
                    title: 'common:uomss',
                    type: 'item',
                    url: '/pim/uoms'
                }
            ]
        }
    ]
};

export default pim;
