import { Box, Button, Grid, IconButton, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import SelectForm from 'ui-component/form/SelectForm';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';

function BarcodesForm({ setBarcodesState, barcodesState, doc, disabled }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setRow2Insert({
            doctype: 'Item Barcode',
            barcode_type: '',
            parent: doc.name,
            parentfield: 'barcodes',
            parenttype: 'Item',
            barcode: ''
        });
    };

    const [row2Insert, setRow2Insert] = useState({
        doctype: 'Item Barcode',
        barcode_type: '',
        parent: doc.name,
        parentfield: 'barcodes',
        parenttype: 'Item',
        barcode: ''
    });

    const handleChangeValues = (e) => {
        setRow2Insert({ ...row2Insert, [e.target.name]: e.target.value });
    };

    const handleInsert = () => {
        if (row2Insert.barcode_type === '') {
            setBarcodesState([...barcodesState, row2Insert]);
            handleClose();
        } else if (row2Insert.barcode_type === 'EAN') {
            if (isValidEAN(row2Insert.barcode)) {
                setBarcodesState([...barcodesState, row2Insert]);
                handleClose();
            } else {
                dispatch(SetNotification({ message: 'This is not a valid EAN Barcode', code: 'error' }));
            }
        } else if (row2Insert.barcode_type === 'UPC-A') {
            if (isValidUPCA(row2Insert.barcode)) {
                setBarcodesState([...barcodesState, row2Insert]);
                handleClose();
            } else {
                dispatch(SetNotification({ message: 'This is not a valid UPC-A Barcode', code: 'error' }));
            }
        }
    };

    const handleDelete = (el) => {
        setBarcodesState(barcodesState.filter((row) => row.barcode !== el.barcode));
    };

    function isValidEAN(eanCode) {
        if (eanCode.length !== 13) {
            return false;
        }
        const check = Number(eanCode[12]);
        let checksum = 0;
        for (let i = 0; i < 12; i++) {
            checksum += (i % 2 === 0 ? 1 : 3) * Number(eanCode[i]);
        }
        checksum = 10 - (checksum % 10);
        if (checksum === 10) {
            checksum = 0;
        }
        return check === checksum;
    }

    function isValidUPCA(upcCode) {
        if (upcCode.length !== 12) {
            return false;
        }
        const check = Number(upcCode[11]);
        let checksum = 0;
        for (let i = 0; i < 11; i++) {
            checksum += (i % 2 === 0 ? 3 : 1) * Number(upcCode[i]);
        }
        checksum = 10 - (checksum % 10);
        if (checksum === 10) {
            checksum = 0;
        }
        return check === checksum;
    }

    return (
        <div style={{ padding: 20 }}>
            <Box>
                <Grid container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                    <Typography fontWeight={600} variant="h4">
                        Barcodes
                    </Typography>
                    <Button color="blue" onClick={handleOpen} disabled={disabled}>
                        <AddIcon sx={{ marginLeft: -1 }} /> Add
                    </Button>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>Barcode</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Barcode type</strong>
                            </TableCell>
                            <TableCell align="right" width={50}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {barcodesState?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <strong>{el.barcode}</strong>
                                </TableCell>
                                <TableCell>{el.barcode_type === '' ? '-' : el.barcode_type}</TableCell>
                                <TableCell align="right" width={50}>
                                    <IconButton disabled={disabled} onClick={() => handleDelete(el)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {(open || barcodesState?.length === 0) && !disabled && (
                            <TableRow key={999}>
                                <TableCell>
                                    <OutlinedInput
                                        value={row2Insert.barcode}
                                        onChange={handleChangeValues}
                                        name="barcode"
                                        placeholder={'Barcode'}
                                    />
                                </TableCell>
                                <TableCell>
                                    <SelectForm
                                        fullwidth
                                        handleChange={handleChangeValues}
                                        value={row2Insert.barcode_type}
                                        name="barcode_type"
                                        data={['', 'EAN', 'UPC-A']}
                                    />
                                </TableCell>
                                <TableCell align="right" width={100}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IconButton onClick={handleInsert} disabled={!row2Insert.barcode}>
                                            <DoneIcon color={!row2Insert.barcode ? 'disabled' : ''} />
                                        </IconButton>
                                        <IconButton onClick={handleClose}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </div>
    );
}

export default BarcodesForm;
