import { Box, Button, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Modal, Pagination, Typography } from '@mui/material';
import React, { useState } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { modalStyle } from 'views/utilities/ModalStyle';
import MainCard from 'ui-component/cards/MainCard';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

function FileList({ files, refetchFilesList }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [file2Delete, setFile2Delete] = useState(null);
    const [open, setOpen] = useState(false);

    const handleOpen = (file) => {
        setOpen(true);
        setFile2Delete(file);
    };

    const handleClose = () => {
        setOpen(false);
        setFile2Delete(null);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDownload = (file) => {
        const url = process.env.REACT_APP_API_URI + file?.url;
        window.open(url, '_blank');
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        await fetch(`${process.env.REACT_APP_API_URI}/api/delete-file`, {
            method: 'DELETE',
            headers: header,
            body: JSON.stringify({ fileUrl: file2Delete?.url })
        })
            .then((res) => {
                if (res) {
                    setIsDeleting(false);
                    dispatch(SetNotification({ code: 'success', message: 'File deleted successfully' }));
                    handleClose();
                    refetchFilesList({});
                }
            })
            .catch(() => {
                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
            });
    };

    const deleteAlert = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    Supprimer définitivement le fichier <strong>{file2Delete?.file}</strong> ?
                </Typography>

                <Box display={'flex'} justifyContent={'flex-end'} gap={1} mt={3}>
                    <LoadingButton loading={isDeleting} color="blue" size="large" onClick={() => handleDelete()} variant="contained">
                        Supprimer
                    </LoadingButton>
                    <Button variant="outlined" color="blue" size="large" onClick={() => handleClose()}>
                        Non
                    </Button>
                </Box>
            </MainCard>
        </Box>
    );

    return (
        <>
            <ImageList cols={4} variant="masonry" gap={20}>
                {files?.slice((page - 1) * 20, page * 20)?.map((el) => (
                    <ImageListItem key={el.name}>
                        {el?.type === 'Image' ? (
                            <img
                                srcSet={`${process.env.REACT_APP_API_URI + el?.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={`${process.env.REACT_APP_API_URI + el?.url}?w=248&fit=crop&auto=format`}
                                alt={el?.file}
                                loading="lazy"
                                style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: '0px 2px 8px #091F461D' }}
                            />
                        ) : (
                            <div
                                style={{
                                    height: 200,
                                    backgroundColor: 'Menu',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 40,
                                    borderTopLeftRadius: 10,
                                    borderTopRightRadius: 10,
                                    boxShadow: '0px 2px 8px #091F461D'
                                }}
                            >
                                <InsertDriveFileIcon color="disabled" sx={{ height: 40, width: 40 }} />
                            </div>
                        )}
                        <ImageListItemBar
                            title={el?.doctype + ': ' + el?.item}
                            subtitle={
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                    <div>{el?.file}</div>
                                    <div style={{ color: theme.palette.grey[400] }}>Added: {el?.creation}</div>
                                </div>
                            }
                            actionIcon={
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <IconButton
                                        onClick={() => handleDownload(el)}
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${el?.file}`}
                                    >
                                        <FileDownloadIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleOpen(el)}
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${el?.file}`}
                                    >
                                        <DeleteIcon color="error" style={{ opacity: 0.5 }} />
                                    </IconButton>
                                </div>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            {Math.ceil(files?.length / 20) > 0 && (
                <Box display="flex" justifyContent="center" m={4}>
                    <Pagination
                        onChange={(event, newPage) => setPage(newPage)}
                        page={page}
                        count={Math.ceil(files?.length / 20)}
                        color="primary"
                    />
                </Box>
            )}

            <Modal onClose={handleClose} open={open}>
                {deleteAlert}
            </Modal>
        </>
    );
}

export default FileList;
