import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { CurrencyFormatter } from 'utils/utils';
import { formatDate } from 'helpers/utils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function POSTotals({ doc }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    return (
        <MainCard
            border
            isWhite
            title={
                <Box display={'flex'} alignItems={'center'}>
                    <MonetizationOnIcon sx={{ mr: 1 }} /> {t('documents:ttls')}
                </Box>
            }
            boxShadow
            divider
        >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 50, alignItems: 'center' }}>
                <div className={classes.orderDetailsContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>N°</TableCell>
                                <TableCell align="left">{t('documents:ah')}</TableCell>
                                <TableCell align="right">{t('documents:rate')}</TableCell>
                                <TableCell align="right">{t('documents:amnt')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {doc?.taxes?.map((el, i) => (
                                <TableRow key={i}>
                                    <TableCell>
                                        <strong>{el?.idx}</strong>
                                    </TableCell>
                                    <TableCell align="left">
                                        <strong>{el?.account_head}</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>{el?.rate?.toFixed(1)}%</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                minimumFractionDigits={3}
                                                value={el?.amount}
                                                currency={globalDefaults?.default_currency}
                                            />
                                        </strong>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <div>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        {t('documents:gt')}:
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                            <CurrencyFormatter
                                locale="fr-FR"
                                minimumFractionDigits={3}
                                value={doc?.grand_total}
                                currency={globalDefaults?.default_currency}
                            />
                        </Typography>
                    </Typography>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        {t('documents:nt')}:
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                            <CurrencyFormatter
                                locale="fr-FR"
                                minimumFractionDigits={3}
                                value={doc?.net_total}
                                currency={globalDefaults?.default_currency}
                            />
                        </Typography>
                    </Typography>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        {t('documents:ttlQty')}:
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                            {doc?.total_quantity}
                        </Typography>
                    </Typography>
                </div>
            </div>
        </MainCard>
    );
}

export default POSTotals;
