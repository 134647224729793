// assets
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    SupportAgentIcon
};

const crm = {
    id: 'crm',
    title: 'CRM',
    caption: 'Gestion CRM',
    type: 'group',
    role: [ROLE.ADMIN],
    children: [
        {
            id: 'CRM',
            title: 'CRM',
            type: 'collapse',
            icon: icons.SupportAgentIcon,
            children: [
                {
                    id: 'customer_group',
                    title: 'crm:customergroup',
                    type: 'item',
                    url: '/crm/customer-category-list-view'
                },
                {
                    id: 'customer',
                    title: 'crm:customers',
                    type: 'item',
                    url: '/crm/customer'
                },
                {
                    id: 'default',
                    title: 'Qrcode',
                    type: 'item',
                    url: '/qrcode/list',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default crm;
