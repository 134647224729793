import {
    STOCK_ENTRY,
    FULFILLMENT,
    POS_OPENING_ENTRY,
    POS_CLOSING_ENTRY,
    POS_INVOICE,
    MATERIAL_REQUEST_PURCHASE,
    MATERIAL_REQUEST_TRANSFER,
    INVENTORY,
    COFFRE
} from './notifications.const';
import moment from 'moment';
import 'moment/locale/fr';

export const getWarehouseCible = (notification) => {
    return notification?.message?.doctype == 'Stock Entry'
        ? notification?.message?.to_warehouse
            ? notification?.message?.to_warehouse
            : notification?.message?.items?.length > 0 && Array.isArray(notification?.message?.items)
            ? notification?.message?.items[0]?.t_warehouse
            : null
        : notification?.message?.set_warehouse;
};
export const getWarehouseTarget = (notification) => {
    return notification?.message?.from_warehouse
        ? notification?.message?.from_warehouse
        : notification?.message?.set_from_warehouse
        ? notification?.message?.set_from_warehouse
        : null;
};

function readByCurrentUser(users, currentUserId) {
    return users.some((user) => user.id === currentUserId);
}

export const FormatNotifications = (notifications) => {
    let currentUserId = JSON.parse(localStorage.getItem('user'))?.id;

    const formattedNotifications = notifications.map((notification) => {
        if (notification?.message) {
            let key = notification?.message?.doctype?.replaceAll(' ', '_').toUpperCase();
            let content = NotificationContent(key, notification);
            if (content && content.fields.length > 0) {
                let text = content.content;
                let warehouseC = getWarehouseCible(notification);
                let warehouseT = getWarehouseTarget(notification);
                text = content.fields.map((field) => {
                    if (notification?.message?.doctype == 'Stock Entry' && field == 'to_warehouse') {
                        text = text?.replace(field, warehouseC);
                    } else {
                        text = text?.replace(field, notification.message[field]);
                    }
                    return text;
                });
                return {
                    created_at: notification.created_at,
                    id: notification.id,
                    url: content.url,
                    text: text[text.length - 1],
                    read: readByCurrentUser(notification?.users, currentUserId),
                    warehouseTarget: warehouseT,
                    warehouseCible: warehouseC,
                    doctype:
                        notification?.message?.doctype == 'Material Request'
                            ? notification?.message?.doctype + ' ' + notification?.message?.material_request_type
                            : notification?.message?.doctype
                };
            }
        }
    });

    return formattedNotifications;
};

export const NotificationContent = (key, data) => {
    let content = null;
    switch (key) {
        case 'MATERIAL_REQUEST':
            if (data?.message?.material_request_type === 'Material Transfer') {
                content = {
                    fields: ['name', 'set_warehouse'],
                    content: MATERIAL_REQUEST_TRANSFER,
                    url: `/transfer-requests/${data?.message && data?.message.hasOwnProperty('name') ? data?.message.name : ''}`
                };
            } else if (data?.message?.material_request_type === 'Purchase') {
                content = {
                    fields: ['name', 'set_warehouse'],
                    content: MATERIAL_REQUEST_PURCHASE,
                    url: `/transfer-requests/${data?.message && data?.message.hasOwnProperty('name') ? data?.message.name : ''}`
                };
            }

            break;
        case 'STOCK_ENTRY':
            content = {
                fields: ['name', 'to_warehouse'],
                content: STOCK_ENTRY,
                url: `/stock-entry/${data?.message && data?.message.hasOwnProperty('name') ? data?.message.name : ''}`
            };
            break;
        case 'FULFILLMENT':
            content = {
                fields: ['name'],
                content: FULFILLMENT,
                url: `/fulfillement/details/${data?.message && data?.message.hasOwnProperty('id') ? data?.message.id : ''}`
            };
            break;
        case 'POS_OPENING_ENTRY':
            content = {
                fields: ['pos_profile'],
                content: POS_OPENING_ENTRY,
                url: ``
            };
            break;
        case 'POS_CLOSING_ENTRY':
            content = {
                fields: ['pos_profile'],
                content: POS_CLOSING_ENTRY,
                url: ``
            };
            break;
        case 'POS_INVOICE':
            content = {
                fields: ['set_warehouse', 'customer'],
                content: POS_INVOICE,
                url: ``
            };
            break;
        case 'INVENTORY':
            content = {
                fields: ['list_id', 'warehouse'],
                content: INVENTORY,
                url: ``
            };
            break;
        case 'COFFRE':
            content = {
                fields: ['amount', 'cashier', 'payment_method', 'warehouse'],
                content: COFFRE,
                url: ``
            };
            break;
        default:
            content = null;
            break;
    }
    return content;
};

export const formatDate = (date, format) => {
    return moment(date).locale('fr').format(format);
};

export const symbol = (currency, currencies) => {
    const x = currencies?.find(function (c, i) {
        if (c.name === currency) return true;
    });
    return x;
};

export const removeHTML = (str) => {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || '';
};

export const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
};

export const addDaystoDate = (date, days) => {
    let result = new Date();
    result.setDate(date.getDate() + days);
    return formatDate(result, 'YYYY-MM-DD');
};

export const statusFormat = (status) => {
    if (i18n.language === 'fr') {
        switch (status) {
            case 'Draft':
                return { value: 'Brouillon', color: 'error' };
            case 'Pending':
                return { value: 'En cours', color: 'warning' };
            case 'Transferred':
                return { value: 'Transféré', color: 'success' };
            case 'Paid':
                return { value: 'Payé', color: 'success' };
            case 'Consolidated':
                return { value: 'Consolidé', color: 'success' };
            case 'Submitted':
                return { value: 'Soumis', color: 'success' };
            case 'Stopped':
                return { value: 'Arrêté', color: 'error' };
            case 'Cancelled':
                return { value: 'Annulé', color: 'error' };
            case 'Partially Ordered':
                return { value: 'Partiellement Commandé', color: 'warning' };
            case 'Ordered':
                return { value: 'Commandé', color: 'success' };
            case 'Issued':
                return { value: 'Délivré', color: 'info' };
            case 'Received':
                return { value: 'Reçu', color: 'success' };
            case 'Return':
                return { value: 'Retour', color: 'warning' };
            case 'Debit Note Issued':
                return { value: 'Note de débit émise', color: 'info' };
            case 'Unpaid':
                return { value: 'Non payé', color: 'error' };
            case 'Overdue':
                return { value: 'En retard de paiement', color: 'error' };
            case 'Internal Transfer':
                return { value: 'Transfert interne', color: 'info' };
            case 'prepared':
                return { value: 'Préparé', color: 'success' };
            case 'picked':
                return { value: 'Prélevé', color: 'success' };
            case 'in packing':
                return { value: 'En emballage', color: 'info' };
            case 'verified':
                return { value: 'Vérifiée', color: 'success' };
            case 'in prepare':
                return { value: 'En préparation', color: 'info' };
            case 'packed':
                return { value: 'Emballée', color: 'success' };
            case 'to verify':
                return { value: 'A vérifier', color: 'info' };
            case 'canceled':
                return { value: 'Annulée', color: 'error' };
            case 'Not Delivered':
                return { value: 'Non Livrée', color: 'warning' };
            case 'Not Billed':
                return { value: 'Non facturée', color: 'warning' };
            case 'To Deliver and Bill':
                return { value: 'Pour livrer et facturer', color: 'info' };
            case 'To Deliver':
                return { value: 'Pour livrer', color: 'info' };
            case 'To Bill':
                return { value: 'Pour facturer', color: 'info' };
            case 'Cancelled':
                return { value: 'Annulée', color: 'error' };
            case 'Completed':
                return { value: 'Terminée', color: 'success' };
            case 'In Progress':
                return { value: 'En cours', color: 'warning' };
            case 'Finished':
                return { value: 'Terminé', color: 'info' };
            case 'Closed':
                return { value: 'Fermée', color: 'success' };
            case 'Fully Delivered':
                return { value: 'Totalement Livrée', color: 'success' };
            case 'Fully Billed':
                return { value: 'Entièrement Facturée', color: 'success' };
            case 'cancel':
                return { value: 'Annulée', color: 'error' };
            default:
                return { value: status };
        }
    } else {
        switch (status) {
            case 'Draft':
                return { value: 'Draft', color: 'error' };
            case 'Pending':
                return { value: 'Pending', color: 'warning' };
            case 'Transferred':
                return { value: 'Transferred', color: 'success' };
            case 'Paid':
                return { value: 'Paid', color: 'success' };
            case 'Consolidated':
                return { value: 'Consolidated', color: 'success' };
            case 'Submitted':
                return { value: 'Submitted', color: 'success' };
            case 'Stopped':
                return { value: 'Stopped', color: 'error' };
            case 'Cancelled':
                return { value: 'Cancelled', color: 'error' };
            case 'Partially Ordered':
                return { value: 'Partially Ordered', color: 'warning' };
            case 'Ordered':
                return { value: 'Ordered', color: 'success' };
            case 'Issued':
                return { value: 'Issued', color: 'info' };
            case 'Received':
                return { value: 'Received', color: 'success' };
            case 'Return':
                return { value: 'Return', color: 'warning' };
            case 'Debit Note Issued':
                return { value: 'Debit Note Issued', color: 'info' };
            case 'Unpaid':
                return { value: 'Unpaid', color: 'error' };
            case 'Overdue':
                return { value: 'Overdue', color: 'error' };
            case 'Internal Transfer':
                return { value: 'Internal Transfer', color: 'info' };
            case 'prepared':
                return { value: 'Prepared', color: 'success' };
            case 'picked':
                return { value: 'Picked', color: 'success' };
            case 'in packing':
                return { value: 'In Packing', color: 'info' };
            case 'verified':
                return { value: 'Verified', color: 'success' };
            case 'in prepare':
                return { value: 'In Prepare', color: 'info' };
            case 'packed':
                return { value: 'Packed', color: 'success' };
            case 'to verify':
                return { value: 'To Verify', color: 'info' };
            case 'canceled':
                return { value: 'Canceled', color: 'error' };
            case 'Not Delivered':
                return { value: 'Not Delivered', color: 'warning' };
            case 'Not Billed':
                return { value: 'Not Billed', color: 'warning' };
            case 'To Deliver and Bill':
                return { value: 'To Deliver and Bill', color: 'info' };
            case 'To Deliver':
                return { value: 'To Deliver', color: 'info' };
            case 'To Bill':
                return { value: 'To Bill', color: 'info' };
            case 'Cancelled':
                return { value: 'Cancelled', color: 'error' };
            case 'Completed':
                return { value: 'Completed', color: 'success' };
            case 'In Progress':
                return { value: 'In Progress', color: 'warning' };
            case 'Finished':
                return { value: 'Finished', color: 'info' };
            case 'Closed':
                return { value: 'Closed', color: 'success' };
            case 'Fully Delivered':
                return { value: 'Fully Delivered', color: 'success' };
            case 'Fully Billed':
                return { value: 'Fully Billed', color: 'success' };

            default:
                return { value: status };
        }
    }
};

export const packMoyenne = {
    height: 233,
    length: 500,
    weight: 455,
    width: 555
};

export const packPetit = {
    height: 200,
    length: 320,
    weight: 310,
    width: 100
};

export const packGrand = {
    height: 500,
    length: 600,
    weight: 500,
    width: 600
};

export const isUnique = (str, contactPhone) => {
    if (Array.isArray(contactPhone)) {
        for (const element of contactPhone) {
            if (element?.phone === str) {
                return false;
            }
        }
        return true;
    }
    return true;
};

export const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
};
