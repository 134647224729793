import React, { useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import MainCard from '../../ui-component/cards/MainCard';
import InputTextField from '../../ui-component/form/InputTextField';
import { useNavigate, useParams } from 'react-router';
import { SetNotification } from '../../store/services/api';
import { formatErpResponse } from '../../utils/utils';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
const AddNewQrcode = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();
    const { id } = useParams();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [error, setError] = React.useState({
        name: false,
        url: false
    });
    const [state, setState] = useState('');
    const handleChange = (event) => {
        setError({ ...error, [event.target.name]: false });
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const re =
        /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            url: ''
        },
        validationSchema: yup.object({
            name: yup.string('Enter your name').max(50, 'Password should be max 50 characters length').required('Name is required'),
            url: yup.string().matches(re, 'URL is not valid').required('Url is required').required('Url is required')
        }),
        onSubmit: (values) => {
            handleAddQrcode();
        }
    });
    const qrcodeData = {
        name: formik.values.name,
        url: formik.values.url
    };

    const handleAddQrcode = async () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/qrcode`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(qrcodeData)
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.message) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    if (id) {
                        dispatch(SetNotification({ message: 'Qr Code créé avec succès', code: 'success' }));
                    } else {
                        dispatch(SetNotification({ message: 'Qr Code créé avec succès', code: 'success' }));
                    }
                    navigate('/qrcode/list');
                }
            });
    };
    const handleAnnuler = () => {
        navigate('/qrcode/list');
    };

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {t('qrcodes:newQrcode')}
        </Box>
    );

    //if (id && isPending) return <Loader />;
    return (
        <div>
            <MainCard title={title}>
                <form onSubmit={formik.handleSubmit}>
                    <Box>
                        <Grid container rowSpacing={2}>
                            <InputTextField
                                required
                                id="name"
                                name="name"
                                label={t('qrcodes:name')}
                                value={formik.values.name}
                                handleChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helper={formik.touched.name && formik.errors.name}
                            />
                            <InputTextField
                                required
                                id="url"
                                name="url"
                                label={t('qrcodes:url')}
                                value={formik.values.url}
                                handleChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.url && Boolean(formik.errors.url)}
                                helper={formik.touched.url && formik.errors.url}
                            />
                        </Grid>
                        <Box display={'flex'} justifyContent={'center'} mt={3}>
                            <Button sx={{ mr: 1 }} onClick={handleAnnuler}>
                                {t('common:cancel')}
                            </Button>
                            <Button size="large" variant="contained" color="blue" type="submit">
                                {t('common:save')}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </MainCard>
        </div>
    );
};
export default AddNewQrcode;
