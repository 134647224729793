import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import MainCard from 'ui-component/cards/MainCard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTheme } from '@emotion/react';
import { formatData, formatColumnsHeaders } from 'utils/formatColumnsHeaders';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'views/utilities/Style';

const DetailsTables = ({ data, loading, title, handleEdit, selectionModel, setSelectionModel, isSupplier }) => {
    const theme = useTheme();
    const classes = useStyles();

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 5,
        page: 0
    });

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [idToDelete, setIdToDelete] = useState(null);
    const { t, i18n } = useTranslation('columns');

    const handleDetails = React.useCallback(
        (id) => () => {
            handleEdit(id);
        },
        []
    );

    const actions = {
        field: 'actions',
        type: 'actions',
        align: 'right',
        headerAlign: 'right',
        getActions: (params) => [
            <GridActionsCellItem
                sx={{ bgcolor: theme.palette.secondary.main }}
                icon={<VisibilityIcon color="primary" />}
                label="Edit"
                onClick={handleDetails(params.id)}
            />
        ]
    };

    const getColumns = (data) => {
        let columns = [];
        if (data && Array.isArray(data) && data?.length > 0) {
            Object.keys(data[0]).map((column) => {
                columns.push({
                    field: column,
                    headerName: t(formatColumnsHeaders(column)),
                    flex: 1
                });
            });

            !isSupplier && columns.push(actions);
            return columns;
        }
        return [];
    };

    useEffect(() => {
        if (!loading && data && data?.length > 0) {
            setRows(formatData(data));
            setColumns(getColumns(data));
        }
    }, [data]);

    return (
        <Box>
            <MainCard border title={title}>
                <Box sx={{ height: 470 }}>
                    <DataGrid
                        onRowDoubleClick={() => handleEdit && handleEdit(selectionModel)}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        rowSelectionModel={selectionModel}
                        experimentalFeatures={{ newEditingApi: true }}
                        loading={loading}
                        rows={rows}
                        columns={columns}
                        getRowId={(row) => row.name}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 }
                            }
                        }}
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnFilter
                        disableColumnMenu
                    />
                </Box>
            </MainCard>
        </Box>
    );
};

export default DetailsTables;
