import React from 'react';
import { Button, Box, Grid, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import SimpleTable from 'ui-component/simpleTable/SimpleTable';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import validator from 'validator';
import { GlobalfilterData } from 'utils/formatColumnsHeaders';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';

const CustomerSearchForm = (props) => {
    const classes = useStyles();
    const { setOpenModal, handleCancel, setFormState } = props;
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [searchedCustomer, setSearchedCustomer] = React.useState([]);
    const [state, setState] = React.useState({
        mobile_no: '',
        customer_name: ''
    });

    const columnsName = ['customer_name', 'mobile_no', 'customer_group'];

    const { data: customers, isPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Customer',
            fields: [
                '`tabCustomer`.`name`',
                '`tabCustomer`.`customer_group`',
                '`tabCustomer`.`territory`',
                '`tabCustomer`.`customer_name`',
                '`tabCustomer`.`customer_type`',
                '`tabCustomer`.`mobile_no`'
            ],
            filters: [['Customer', 'disabled', '=', 0]]
        },
        'POST'
    );

    const SearchCustomer = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
        const data = GlobalfilterData(customers, event.target.value.toLowerCase(), ['customer_name', 'mobile_no']);
        data?.length ? setSearchedCustomer(data) : setSearchedCustomer([]);
    };

    useEffect(() => {
        if (selectionModel.length != 0) {
            setFormState({ party_name: selectionModel[0] });
            handleCancel();
        }
    }, [selectionModel]);

    if (isPending) return <Loader />;

    return (
        <div className={classes.paper}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                    <InputLabel>Customer Name</InputLabel>
                    <OutlinedInput
                        value={state.customer_name}
                        name="customer_name"
                        autoComplete="off"
                        label="Customer Name"
                        placeholder="Customer Name"
                        onChange={SearchCustomer}
                    />
                </FormControl>
                <FormControl sx={{ width: '100%' }}>
                    <InputLabel error={state.mobile_no && !validator.isMobilePhone(state.mobile_no)}>Mobile</InputLabel>
                    <OutlinedInput
                        value={state.mobile_no}
                        name="mobile_no"
                        autoComplete="off"
                        placeholder="Mobile"
                        label="Mobile"
                        onChange={SearchCustomer}
                        error={state.mobile_no && !validator.isMobilePhone(state.mobile_no)}
                    />
                </FormControl>
            </div>
            <SimpleTable
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                autoHeight={300}
                data={searchedCustomer}
                columnsName={columnsName}
            />
            <Box justifyContent="right" display="flex" mt={2}>
                <Button variant="outlined" color="blue" size="large" onClick={handleCancel}>
                    Cancel
                </Button>
            </Box>
        </div>
    );
};

export default CustomerSearchForm;
