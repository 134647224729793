import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import { useStyles } from 'views/utilities/Style';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CustomerGroupForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const customerGroupsReqData = {
        doctype: 'Customer Group',
        fields: ['`tabCustomer Group`.`name`'],
        filters: [['Customer Group', 'is_group', '=', 1]],
        start: 0,
        page_length: 50
    };

    const { data: itemGroups, isPending } = useFetch(`/api/get-list`, customerGroupsReqData, 'POST');

    const { data: priceLists, isPriceListsPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Price List', fields: ['`tabPrice List`.`name`'], start: 0, page_length: 50 },
        'POST'
    );

    const [isGroup, setIsGroup] = useState(false);
    const [customerGroupName, setCustomerGroupName] = useState('');
    const handleChange = (e) => setCustomerGroupName(e.target.value);
    const [parent, setParent] = useState('');
    const [defaultPriceList, setDefaultPriceList] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);

    const handleSelectChange = (event) => {
        const {
            target: { value }
        } = event;
        setParent(value);
    };

    const handleSelectChangePriceList = (event) => {
        const {
            target: { value }
        } = event;
        setDefaultPriceList(value);
    };

    const newCustomerGroupData = {
        doctype: 'Customer Group',
        default_price_list: defaultPriceList,
        is_group: isGroup ? 1 : 0,
        customer_group_name: customerGroupName,
        parent: parent,
        is_root: false
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleAddCustomerGroup = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/products/add-category`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(newCustomerGroupData)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                dispatch(SetNotification({ code: 'success', message: 'La catégorie a été créée avec succès' }));
                navigate('/crm/customer-category-list-view');
            });
    };

    return (
        <Box>
            <MainCard
                title={
                    <Box display={'flex'} flexDirection={'column'}>
                        <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                        {'Nouvelle catégorie'}
                    </Box>
                }
            >
                <Box>
                    <FormControlLabel
                        label="Parent"
                        control={<Checkbox color="blue" checked={isGroup} onChange={() => setIsGroup(!isGroup)} />}
                    />
                    <Typography id="modal-modal-description" sx={{ fontSize: 11 }}>
                        Seuls les nœuds feuilles sont autorisés dans la transaction
                    </Typography>
                    <InputTextField
                        sx={{ mt: 0 }}
                        error={customerGroupName === ''}
                        handleChange={handleChange}
                        value={customerGroupName}
                        required
                        label={t('products:categoryName')}
                        id="outlined-size-small"
                        size="small"
                    />
                    <SelectForm
                        sx={{ mt: -2 }}
                        label={t('columns:parent_customer_group')}
                        value={parent}
                        handleChange={handleSelectChange}
                        data={itemGroups}
                        propToRender={'name'}
                    />
                    <SelectForm
                        sx={{ mt: -2 }}
                        label={t('products:defaultPriceList')}
                        value={defaultPriceList}
                        handleChange={handleSelectChangePriceList}
                        data={priceLists}
                        propToRender={'name'}
                    />
                </Box>
                <Grid container xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'center'} mt={4}>
                            <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                                {t('common:cancel')}
                            </Button>
                            <LoadingButton
                                disabled={customerGroupName === '' || parent === ''}
                                onClick={handleAddCustomerGroup}
                                size="large"
                                variant="contained"
                                color="blue"
                                loading={loadingButton}
                            >
                                {t('common:create')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};

export default CustomerGroupForm;
